import React, { useState } from 'react';
import { reactGlossary } from '../utils/reactGlossary';
import { Search, ChevronDown, ChevronUp, Code as CodeIcon, Copy, Eye } from 'lucide-react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneLight } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { LiveProvider, LivePreview, LiveError } from 'react-live';

const Glossary = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [expandedTerms, setExpandedTerms] = useState({});
  const [copiedStates, setCopiedStates] = useState({});
  const [previewStates, setPreviewStates] = useState({});

  // Filter glossary based on search term
  const filteredGlossary = reactGlossary.filter(
    (item) =>
      item.term.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.definition.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Toggle expansion state for glossary items
  const toggleTerm = (term) => {
    setExpandedTerms((prev) => ({
      ...prev,
      [term]: !prev[term],
    }));
  };

  // Copy code to clipboard (uses original, unmodified example)
  const handleCopy = (code, term, index) => {
    navigator.clipboard.writeText(code).then(() => {
      setCopiedStates((prev) => ({
        ...prev,
        [`${term}-${index}`]: true,
      }));
      setTimeout(() => {
        setCopiedStates((prev) => ({
          ...prev,
          [`${term}-${index}`]: false,
        }));
      }, 2000); // Reset "Copied" state after 2 seconds
    });
  };

  // Toggle preview visibility with debug logging
  const handlePreview = (term, index) => {
    setPreviewStates((prev) => {
      const key = `${term}-${index}`;
      const newState = !prev[key];
      console.log(`Toggling preview for ${key}: ${newState ? 'ON' : 'OFF'}`);
      console.log('Current previewStates:', { ...prev, [key]: newState });
      return {
        ...prev,
        [key]: newState,
      };
    });
  };

  // Scope for LiveProvider with common React dependencies
  const liveScope = {
    React,
    useState,
  };

  // Extract and prepare code for preview
  const getPreviewCode = (code) => {
    try {
      // Remove imports and clean up
      const withoutImports = code
        .replace(/import\s+.*?\s+from\s+['"].*?['"];?\s*/g, '')
        .replace(/\n\s*\n/g, '\n')
        .trim();

      // Wrap in an IIFE to scope variables and return App()
      const previewCode = `
        (function() {
          ${withoutImports}
          return App();
        })()
      `;

      console.log('Generated preview code:', previewCode);
      return previewCode;
    } catch (error) {
      console.error('Error generating preview code:', error);
      return '<div>Error: Unable to render preview. Check console for details.</div>';
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 text-gray-900 py-10 px-6 antialiased">
      <div className="max-w-4xl mx-auto">
        {/* Header Section */}
        <header className="text-center mb-10">
          <h1 className="text-4xl font-bold text-gray-800 mb-2">React Terminologies</h1>
          <p className="text-lg text-gray-600 font-light">
            Explore key React concepts with clear definitions and examples
          </p>
        </header>

        {/* Search Bar */}
        <div className="relative mb-10">
          <input
            type="text"
            placeholder="Search terms..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full p-3 pl-10 rounded-md bg-white border border-gray-300 text-gray-800 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent shadow-sm hover:shadow-md transition-shadow duration-200"
          />
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-500" />
        </div>

        {/* Glossary List */}
        <div className="space-y-5">
          {filteredGlossary.length === 0 ? (
            <p className="text-center text-gray-500 text-lg font-light">
              No matching terms found.
            </p>
          ) : (
            filteredGlossary.map(({ term, category, definition, useCase, example, relatedTerms }) => (
              <div
                key={term}
                className="bg-white rounded-lg border border-gray-200 shadow-sm hover:shadow-md transition-shadow duration-200"
              >
                {/* Glossary Item Header */}
                <button
                  onClick={() => toggleTerm(term)}
                  className="w-full px-5 py-4 flex justify-between items-center text-left hover:bg-gray-50 transition-colors duration-150"
                >
                  <div>
                    <h3 className="text-xl font-semibold text-blue-600">{term}</h3>
                    <p className="text-sm text-gray-500">{category}</p>
                  </div>
                  {expandedTerms[term] ? (
                    <ChevronUp className="h-5 w-5 text-gray-500" />
                  ) : (
                    <ChevronDown className="h-5 w-5 text-gray-500" />
                  )}
                </button>

                {/* Expanded Content */}
                {expandedTerms[term] && (
                  <div className="px-5 pb-5 border-t border-gray-200 text-gray-700">
                    <p className="mt-3 mb-3 text-base leading-7">{definition}</p>
                    {useCase && (
                      <p className="mb-3 text-base leading-7">
                        <strong className="text-blue-600 font-medium">Use Case:</strong>{' '}
                        {useCase}
                      </p>
                    )}
                    {example && (
                      <div className="mb-3">
                        <div className="flex items-center mb-2">
                          <CodeIcon className="h-4 w-4 text-blue-500 mr-2" />
                          <span className="text-sm font-medium text-blue-600">
                            Example{Array.isArray(example) && example.length > 1 ? "s" : ""}:
                          </span>
                        </div>
                        {Array.isArray(example) ? (
                          example.map((ex, index) => (
                            <div key={index} className="relative mb-4">
                              <SyntaxHighlighter
                                language="jsx"
                                style={oneLight}
                                customStyle={{
                                  borderRadius: '0.375rem',
                                  padding: '1rem 5rem 1rem 1rem',
                                  background: '#f8fafc',
                                  fontSize: '0.95rem',
                                  lineHeight: '1.5',
                                }}
                              >
                                {ex}
                              </SyntaxHighlighter>
                              <div className="absolute top-2 right-2 flex space-x-2">
                                <button
                                  onClick={() => handlePreview(term, index)}
                                  className="w-8 h-8 flex items-center justify-center rounded-md bg-blue-100 hover:bg-blue-200 text-blue-700 transition-colors duration-200"
                                  title="Toggle preview"
                                >
                                  <Eye className="w-4 h-4" />
                                </button>
                                <button
                                  onClick={() => handleCopy(ex, term, index)}
                                  className="w-8 h-8 flex items-center justify-center rounded-md bg-gray-200 hover:bg-gray-300 text-gray-700 transition-colors duration-200"
                                  title="Copy code"
                                >
                                  <Copy className="w-4 h-4" />
                                  {copiedStates[`${term}-${index}`] && (
                                    <span className="absolute -top-8 left-1/2 transform -translate-x-1/2 bg-green-600 text-white text-xs px-2 py-1 rounded">
                                      Copied!
                                    </span>
                                  )}
                                </button>
                              </div>
                              {/* Preview Section */}
                              {previewStates[`${term}-${index}`] && (
                                <div className="mt-2 p-4 border border-gray-200 rounded-md bg-gray-50">
                                  <LiveProvider code={getPreviewCode(ex)} scope={liveScope}>
                                    <div>
                                      <strong>Preview:</strong>
                                      <LivePreview className="mb-2 border p-2" />
                                    </div>
                                    <div>
                                      <strong>Error (if any):</strong>
                                      <LiveError className="text-red-600 text-sm" />
                                    </div>
                                  </LiveProvider>
                                </div>
                              )}
                            </div>
                          ))
                        ) : (
                          <div className="relative">
                            <SyntaxHighlighter
                              language="jsx"
                              style={oneLight}
                              customStyle={{
                                borderRadius: '0.375rem',
                                padding: '1rem 5rem 1rem 1rem',
                                background: '#f8fafc',
                                fontSize: '0.95rem',
                                lineHeight: '1.5',
                              }}
                            >
                              {example}
                            </SyntaxHighlighter>
                            <div className="absolute top-2 right-2 flex space-x-2">
                              <button
                                onClick={() => handlePreview(term, 0)}
                                className="w-8 h-8 flex items-center justify-center rounded-md bg-blue-100 hover:bg-blue-200 text-blue-700 transition-colors duration-200"
                                title="Toggle preview"
                              >
                                <Eye className="w-4 h-4" />
                              </button>
                              <button
                                onClick={() => handleCopy(example, term, 0)}
                                className="w-8 h-8 flex items-center justify-center rounded-md bg-gray-200 hover:bg-gray-300 text-gray-700 transition-colors duration-200"
                                title="Copy code"
                              >
                                <Copy className="w-4 h-4" />
                                {copiedStates[`${term}-0`] && (
                                  <span className="absolute -top-8 left-1/2 transform -translate-x-1/2 bg-green-600 text-white text-xs px-2 py-1 rounded">
                                    Copied!
                                  </span>
                                )}
                              </button>
                            </div>
                            {/* Preview Section */}
                            {previewStates[`${term}-0`] && (
                              <div className="mt-2 p-4 border border-gray-200 rounded-md bg-gray-50">
                                <LiveProvider code={getPreviewCode(example)} scope={liveScope}>
                                  <div>
                                    <strong>Preview:</strong>
                                    <LivePreview className="mb-2 border p-2" />
                                  </div>
                                  <div>
                                    <strong>Error (if any):</strong>
                                    <LiveError className="text-red-600 text-sm" />
                                  </div>
                                </LiveProvider>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                    {relatedTerms && (
                      <p className="text-base leading-7">
                        <strong className="text-blue-600 font-medium">Related Terms:</strong>{' '}
                        <span className="text-gray-600">{relatedTerms.join(', ')}</span>
                      </p>
                    )}
                  </div>
                )}
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default Glossary;