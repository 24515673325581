import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import Footer from './components/footer/Footer'
// import './assets/styles/landing.scss'
import useFetch from './services/useFetch'

// const url = 'https://restcountries.eu/rest/v2/all'
// const url = 'https://api.thecatapi.com/v1/breeds'
// https://api.thecatapi.com/v1/images/search?breed_id=
const Header = (props) => {
  return (
    <header className='cat-header'>
      <div className='header-wrapper'>
        <h1>30 Days Of React</h1>
      </div>
    </header>
  )
}

const Main = (props) => {
  const { catImage, flag } = props
  console.log(catImage, flag)
  return (
    <main>
      <div className='main-wrapper'>
        <section className='projects-section'>
          <h1 className='welcome'>
            Lear React by practicing and building Applications
          </h1>
          <p>
            In the 30 React, you will have a chance to practice and build
            building more than 30 applications.
          </p>
          <h1 className='project-title'>Projects</h1>
          <div className='project-cards'>
            <div className='project-card'>
              <NavLink to='/day-19/cats' target='_blank'>
                <div className='project-card-image'>
                  <img src={catImage} loading='lazy' alt={''} />
                </div>
                <div className='project-card-title'>
                  <h3> Day 19: Cats API</h3>
                </div>
              </NavLink>
            </div>
            <div className='project-card'>
              <NavLink
                to='/day-20/cats'
                target='_blank'
                className='project-card-image'
              >
                <div className='project-card-image'>
                  <img src={catImage} loading='lazy' alt={''} />
                </div>
                <div>
                  <h3> Day 20: Cats API</h3>
                </div>
              </NavLink>
            </div>
            <div className='project-card'>
              <NavLink to='/day-23/countries-data' target='_blank'>
                <div className='project-card-image'>
                  <img src={flag && flag.flag} loading='lazy' alt={''} />
                </div>
                <div>
                  <h3>Day 24: Countries Data</h3>
                </div>
              </NavLink>
            </div>
          </div>
        </section>
      </div>
    </main>
  )
}

// CSS styles in JavaScript Object
const buttonStyles = {
  backgroundColor: '#61dbfb',
  padding: 10,
  border: 'none',
  borderRadius: 5,
  margin: '3px auto',
  cursor: 'pointer',
  fontSize: 22,
  color: 'white',
}

const Projects = (props) => {
  const catURL = 'https://api.thecatapi.com/v1/breeds'
  const countriesURL = 'https://restcountries.eu/rest/v2/all'

  // const catsData = useFetch(catURL).map(
  //   (cat) => `https://api.thecatapi.com/v1/images/search?breed_id=${cat.id}`
  // )
  // console.log(catsData)
  const catsData = [
    'https://api.thecatapi.com/v1/images/search?breed_id=abys',
    'https://api.thecatapi.com/v1/images/search?breed_id=aege',
    'https://api.thecatapi.com/v1/images/search?breed_id=abob',
    'https://api.thecatapi.com/v1/images/search?breed_id=acur',
    'https://api.thecatapi.com/v1/images/search?breed_id=asho',
    'https://api.thecatapi.com/v1/images/search?breed_id=awir',
    'https://api.thecatapi.com/v1/images/search?breed_id=amau',
    'https://api.thecatapi.com/v1/images/search?breed_id=amis',
    'https://api.thecatapi.com/v1/images/search?breed_id=bali',
    'https://api.thecatapi.com/v1/images/search?breed_id=bamb',
    'https://api.thecatapi.com/v1/images/search?breed_id=beng',
    'https://api.thecatapi.com/v1/images/search?breed_id=birm',
    'https://api.thecatapi.com/v1/images/search?breed_id=bomb',
    'https://api.thecatapi.com/v1/images/search?breed_id=bslo',
    'https://api.thecatapi.com/v1/images/search?breed_id=bsho',
    'https://api.thecatapi.com/v1/images/search?breed_id=bure',
    'https://api.thecatapi.com/v1/images/search?breed_id=buri',
    'https://api.thecatapi.com/v1/images/search?breed_id=cspa',
    'https://api.thecatapi.com/v1/images/search?breed_id=ctif',
    'https://api.thecatapi.com/v1/images/search?breed_id=char',
    'https://api.thecatapi.com/v1/images/search?breed_id=chau',
    'https://api.thecatapi.com/v1/images/search?breed_id=chee',
    'https://api.thecatapi.com/v1/images/search?breed_id=csho',
    'https://api.thecatapi.com/v1/images/search?breed_id=crex',
    'https://api.thecatapi.com/v1/images/search?breed_id=cymr',
    'https://api.thecatapi.com/v1/images/search?breed_id=cypr',
    'https://api.thecatapi.com/v1/images/search?breed_id=drex',
    'https://api.thecatapi.com/v1/images/search?breed_id=dons',
    'https://api.thecatapi.com/v1/images/search?breed_id=lihu',
    'https://api.thecatapi.com/v1/images/search?breed_id=emau',
    'https://api.thecatapi.com/v1/images/search?breed_id=ebur',
    'https://api.thecatapi.com/v1/images/search?breed_id=esho',
    'https://api.thecatapi.com/v1/images/search?breed_id=hbro',
    'https://api.thecatapi.com/v1/images/search?breed_id=hima',
    'https://api.thecatapi.com/v1/images/search?breed_id=jbob',
    'https://api.thecatapi.com/v1/images/search?breed_id=java',
    'https://api.thecatapi.com/v1/images/search?breed_id=khao',
    'https://api.thecatapi.com/v1/images/search?breed_id=kora',
    'https://api.thecatapi.com/v1/images/search?breed_id=kuri',
    'https://api.thecatapi.com/v1/images/search?breed_id=lape',
    'https://api.thecatapi.com/v1/images/search?breed_id=mcoo',
    'https://api.thecatapi.com/v1/images/search?breed_id=mala',
    'https://api.thecatapi.com/v1/images/search?breed_id=manx',
    'https://api.thecatapi.com/v1/images/search?breed_id=munc',
    'https://api.thecatapi.com/v1/images/search?breed_id=nebe',
    'https://api.thecatapi.com/v1/images/search?breed_id=norw',
    'https://api.thecatapi.com/v1/images/search?breed_id=ocic',
    'https://api.thecatapi.com/v1/images/search?breed_id=orie',
    'https://api.thecatapi.com/v1/images/search?breed_id=pers',
    'https://api.thecatapi.com/v1/images/search?breed_id=pixi',
    'https://api.thecatapi.com/v1/images/search?breed_id=raga',
    'https://api.thecatapi.com/v1/images/search?breed_id=ragd',
    'https://api.thecatapi.com/v1/images/search?breed_id=rblu',
    'https://api.thecatapi.com/v1/images/search?breed_id=sava',
    'https://api.thecatapi.com/v1/images/search?breed_id=sfol',
    'https://api.thecatapi.com/v1/images/search?breed_id=srex',
    'https://api.thecatapi.com/v1/images/search?breed_id=siam',
    'https://api.thecatapi.com/v1/images/search?breed_id=sibe',
    'https://api.thecatapi.com/v1/images/search?breed_id=sing',
    'https://api.thecatapi.com/v1/images/search?breed_id=snow',
    'https://api.thecatapi.com/v1/images/search?breed_id=soma',
    'https://api.thecatapi.com/v1/images/search?breed_id=sphy',
    'https://api.thecatapi.com/v1/images/search?breed_id=tonk',
    'https://api.thecatapi.com/v1/images/search?breed_id=toyg',
    'https://api.thecatapi.com/v1/images/search?breed_id=tang',
    'https://api.thecatapi.com/v1/images/search?breed_id=tvan',
    'https://api.thecatapi.com/v1/images/search?breed_id=ycho',
  ]
  const index = Math.floor(Math.random() * catsData.length)

  const singleCatData = useFetch(catsData[index])
  console.log(singleCatData)
  const catImage = singleCatData[0]
  console.log(catImage)

  const countriesData = useFetch(countriesURL)
  const randIndx = Math.floor(Math.random() * countriesData.length)

  return (
    <>
      {/* <Header /> */}
      <Main
        catImage={catImage && catImage.url}
        flag={countriesData[randIndx]}
      />
      {/* <Footer /> */}
    </>
  )
}

export default Projects
