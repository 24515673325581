// src/data/reactGlossary.js
import { Heart } from 'lucide-react' // Assuming lucide-react is available

export const reactGlossary = [
  // Core Concepts
  {
    term: "JSX",
    category: "Core Concepts",
    definition: "JavaScript XML - A syntax extension for JavaScript that allows embedding HTML-like code within JavaScript, transpiled by Babel into React.createElement calls.",
    explanation: "JSX enhances readability by merging HTML-like syntax with JavaScript, enabling declarative UI design. Unlike plain HTML, it’s a sugar syntax that Babel converts into function calls for React to render. In your project, JSX powers elements like the `Post` title (e.g., `<h1>{post.title}</h1>`) or `Comment` content, seamlessly blending static markup with dynamic data via curly braces `{}`. For example, injecting author data like `author.name` into a post’s UI makes it dynamic and reusable across your app.",
    useCase: "Crafting UI layouts in JavaScript, like displaying a post’s title with its author’s name or a personalized welcome message.",
    example: `
// Single element with title data
const title = <h1 className="title">30 Days of React</h1>;
const App = () => {
  return (
  <div>
  {title}
  </div>
  )
  }
    `,
    relatedTerms: ["JSX", "Components"],
  },
  {
    term: "JSX Composition",
    category: "Core Concepts",
    definition: "Combining multiple JSX elements to build a React UI.",
    useCase: "Structuring a simple app layout with header, main, and footer.",
    example: `
      import React from "react";

      const author = {
        name: "Asabeneh Yetayeh",
        createdAt: "October 1, 2020",
      };

      const title = <h1 className="title">30 Days of React</h1>;

      const header = (
        <header className="p-4 bg-gray-100 rounded-lg shadow-md">
          {title}
          <p className="text-gray-700 mt-2">Written by <strong>{author.name}</strong></p>
          <small className="text-gray-500">Published on {author.createdAt}</small>
        </header>
      );

      const main = (
        <main className="p-4">
          <h2>Welcome to the 30 Days of React Challenge!</h2>
          <p>This is a structured learning path to help you master React in just 30 days. Each day focuses on a different concept, starting from basic elements to advanced topics like state management and hooks.</p>
          <p>In this course, you'll:</p>
          <ul>
            <li>Learn the fundamentals of JSX</li>
            <li>Build interactive UIs with React components</li>
            <li>Understand React's component lifecycle</li>
          </ul>
          <p>By the end of the course, you'll be able to create dynamic web applications using React!</p>
        </main>
      );

      const footer = (
        <footer className="p-4 bg-gray-100 text-center">
          <p className="text-gray-700">Copyright © {author.name} 2025</p>
        </footer>
      );

      const App = () => {
        return (
          <>
            {header}
            {main}
            {footer}
          </>
        );
      };
    `,
    relatedTerms: ["JSX", "Components"],
  },
  {
    term: "Components",
    category: "Core Concepts",
    definition: "Reusable, self-contained pieces of code that encapsulate UI logic and return React elements to define how a part of the UI should look.",
    explanation: "Components are the building blocks of React apps, promoting reusability and modularity. In your app, `Post`, `Comment`, and `Reply` are components that handle specific UI sections, like displaying a post or a comment thread, making it easy to reuse them across pages.",
    useCase: "Creating modular UI elements like a post card or a comment box that can be reused with different data.",
    example: `
  import React from "react";

// AuthorInfo component that accepts props to display author data
 const data = {
    title: "30 Days of React",
    author: {
      name: "Asabeneh Yetayeh",
    },
  };
const AuthorInfo = () => (
  <div>
    <p className="text-gray-700 mt-2">Written by <strong>{data.author.name}</strong></p>
    <small className="text-gray-500">Published on {data.author.createdAt}</small>
  </div>
);

// Header component that passes data as props to the AuthorInfo component
const Header = () => {
  const title = "30 Days of React";
  const author = {
    name: "Asabeneh Yetayeh",
    createdAt: "October 1, 2020",
  };

  return (
    <header className="p-4 bg-gray-100 rounded-lg shadow-md">
      <h1 className="title">{title}</h1>
      {/* Passing author data as props */}
      <AuthorInfo name={author.name} createdAt={author.createdAt} />
    </header>
  );
};

// MainContent component
const MainContent = () => (
  <main className="p-4">
    <h2>Welcome to the 30 Days of React Challenge!</h2>
    <p>This is a structured learning path to help you master React in just 30 days. Each day focuses on a different concept, starting from basic elements to advanced topics like state management and hooks.</p>
    <p>In this course, you'll:</p>
    <ul>
      <li>Learn the fundamentals of JSX</li>
      <li>Build interactive UIs with React components</li>
      <li>Understand React's component lifecycle</li>
    </ul>
    <p>By the end of the course, you'll be able to create dynamic web applications using React!</p>
  </main>
);

// Footer component
const Footer = () => {
  return (
    <footer className="p-4 bg-gray-100 text-center">
      <p className="text-gray-700">Copyright &copy; {data.author.name} 2025</p>
    </footer>
  );
};

// App component that brings everything together and passes props to Footer
const App = () => {
 

  return (
    <>
      <Header />
      <MainContent />
      {/* Passing authorName as prop to Footer */}
      <Footer  />
    </>
  );
};

// Usage: <App />
    `,
    relatedTerms: ["Props", "State", "JSX"]
  },
  {
    term: "Props",
    category: "Core Concepts",
    definition: "Read-only inputs passed from parent to child components to customize their behavior or appearance, enabling dynamic and reusable UI.",
    explanation: "Props are how data flows down the component tree. In your `Post` component, props like `title`, `likes`, and `comments` are passed to display post details, while in `Comment`, `addReply` and `likeComment` props enable interaction. They’re immutable within the component, ensuring predictable behavior.",
    useCase: "Passing post data to a `Post` component or a callback to handle liking a comment.",
    example: `
      import React from "react";

// AuthorInfo component without destructuring
const AuthorInfo = (props) => (
  <div>
    <p className="text-gray-700 mt-2">Written by <strong>{props.name}</strong></p>
    <small className="text-gray-500">Published on {props.createdAt}</small>
  </div>
);

// Header component without destructuring
const Header = (props) => {
  return (
    <header className="p-4 bg-gray-100 rounded-lg shadow-md">
      <h1 className="title">{props.title}</h1>
      {/* Passing author data as props */}
      <AuthorInfo name={props.author.name} createdAt={props.author.createdAt} />
    </header>
  );
};

// MainContent component without destructuring
const MainContent = () => (
  <main className="p-4">
    <h2>Welcome to the 30 Days of React Challenge!</h2>
    <p>This is a structured learning path to help you master React in just 30 days. Each day focuses on a different concept, starting from basic elements to advanced topics like state management and hooks.</p>
    <p>In this course, you'll:</p>
    <ul>
      <li>Learn the fundamentals of JSX</li>
      <li>Build interactive UIs with React components</li>
      <li>Understand React's component lifecycle</li>
    </ul>
    <p>By the end of the course, you'll be able to create dynamic web applications using React!</p>
  </main>
);

// Footer component without destructuring
const Footer = (props) => {
  return (
    <footer className="p-4 bg-gray-100 text-center">
      <p className="text-gray-700">Copyright &copy; {props.authorName} 2025</p>
    </footer>
  );
};

// App component without destructuring
const App = () => {
  // Centralized data source
  const data = {
    title: "30 Days of React",
    author: {
      name: "Asabeneh Yetayeh",
      createdAt: "October 1, 2020",
    },
  };

  return (
    <>
      {/* Passing centralized data as props to child components */}
      <Header title={appData.title} author={data.author} />
      <MainContent />
      <Footer authorName={data.author.name} />
    </>
  );
};

// Usage: <App />

    `,
    relatedTerms: ["Components", "DefaultProps", "PropTypes"]
  },
   {
    term: "Props Destructuring",
    category: "Core Concepts",
    definition:
      "A JavaScript syntax feature used in React to extract specific properties from the props object directly in a component’s parameter list, improving code readability and conciseness.",
    explanation:
      "Props destructuring simplifies accessing prop values by unpacking them at the component’s entry point. In the '30 Days of React' challenge, components like `Header` destructure `title` and `author` to display challenge details without repeatedly referencing `props`. This technique reduces boilerplate and makes the code cleaner, especially when dealing with multiple props.",
    useCase:
      "Extracting `title` and `author` in a `Header` component or `day` and `totalDays` in a progress tracker for the '30 Days of React' challenge.",
    example: `
      import React from "react";
  
      // AuthorInfo component with props destructuring
      const AuthorInfo = ({ name, createdAt }) => (
        <div>
          <p className="text-gray-700 mt-2">Written by <strong>{name}</strong></p>
          <small className="text-gray-500">Published on {createdAt}</small>
        </div>
      );
  
      // Header component with props destructuring
      const Header = ({ title, author }) => (
        <header className="p-4 bg-gray-100 rounded-lg shadow-md">
          <h1 className="title text-3xl font-bold text-indigo-800">{title}</h1>
          <AuthorInfo name={author.name} createdAt={author.createdAt} />
        </header>
      );
  
      // ProgressTracker component with props destructuring
      const ProgressTracker = ({ day, totalDays }) => (
        <main className="p-4 my-6 bg-white rounded-lg shadow-md">
          <section className="max-w-2xl mx-auto">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              Day {day} of {totalDays}
            </h2>
            <p className="text-gray-600 leading-relaxed mb-4">
              You’re making progress in the <strong>30 Days of React</strong> challenge! 
              Keep exploring React concepts daily.
            </p>
            <a
              href="https://github.com/Asabeneh/30-Days-Of-React"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-block px-6 py-2 bg-indigo-600 text-white rounded-lg font-semibold hover:bg-indigo-700 transition-colors"
            >
              View Challenge
            </a>
          </section>
        </main>
      );
  
      // Footer component with props destructuring
      const Footer = ({ authorName }) => (
        <footer className="p-4 bg-gray-100 rounded-lg shadow-md text-center">
          <p className="text-gray-600">
            Copyright © {new Date().getFullYear()} {authorName}. All rights reserved.
          </p>
        </footer>
      );
  
      // App component with props destructuring
      const App = () => {
        const data = {
          title: "30 Days of React",
          author: {
            name: "Asabeneh Yetayeh",
            createdAt: "October 1, 2020",
          },
          day: 5, // Example day in the challenge
          totalDays: 30,
        };
  
        const { title, author, day, totalDays } = data;
  
        return (
          <div className="min-h-screen bg-gray-50 flex flex-col">
            <div className="max-w-4xl mx-auto w-full flex-grow">
              <Header title={title} author={author} />
              <ProgressTracker day={day} totalDays={totalDays} />
              <Footer authorName={author.name} />
            </div>
          </div>
        );
      };
  
      // Usage: <App />
    `,
    relatedTerms: ["Props", "Components", "ES6 Destructuring"],
  },
  {
    term: "Props Spreading",
    category: "Core Concepts",
    definition:
      "A JavaScript feature using the spread operator (...) to pass all properties of an object as individual props to a React component, simplifying prop passing and enhancing reusability.",
    explanation:
      "Props spreading allows you to pass an entire object’s properties to a component without manually specifying each one. In the '30 Days of React' challenge, spreading is useful for passing author data or challenge metadata to components like `Header` or `AuthorInfo`. It reduces redundancy and makes components more flexible, though it should be used judiciously to avoid passing unnecessary props.",
    useCase:
      "Passing all author details (e.g., name, createdAt) to an `AuthorInfo` component or challenge data to a `ProgressTracker` in the '30 Days of React' challenge.",
    example: `
      import React from "react";
  
      // AuthorInfo component with spread props
      const AuthorInfo = ({ name, createdAt, ...rest }) => (
        <div>
          <p className="text-gray-700 mt-2">Written by <strong>{name}</strong></p>
          <small className="text-gray-500">Published on {createdAt}</small>
          {/* Optional: Display any additional spread props */}
          {Object.keys(rest).length > 0 && (
            <p className="text-gray-600">Additional Info: {JSON.stringify(rest)}</p>
          )}
        </div>
      );
  
      // Header component with spread props
      const Header = ({ title, ...authorProps }) => (
        <header className="p-4 bg-gray-100 rounded-lg shadow-md">
          <h1 className="title text-3xl font-bold text-indigo-800">{title}</h1>
          {/* Spread author props to AuthorInfo */}
          <AuthorInfo {...authorProps} />
        </header>
      );
  
      // ProgressTracker component with spread props
      const ProgressTracker = ({ day, totalDays, ...extraProps }) => (
        <main className="p-4 my-6 bg-white rounded-lg shadow-md">
          <section className="max-w-2xl mx-auto">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              Day {day} of {totalDays}
            </h2>
            <p className="text-gray-600 leading-relaxed mb-4">
              You’re progressing through the <strong>30 Days of React</strong> challenge!
              Explore daily lessons to master React.
            </p>
            {/* Optional: Show extra spread props */}
            {Object.keys(extraProps).length > 0 && (
              <p className="text-gray-500">Extra: {JSON.stringify(extraProps)}</p>
            )}
            <a
              href="https://github.com/Asabeneh/30-Days-Of-React"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-block px-6 py-2 bg-indigo-600 text-white rounded-lg font-semibold hover:bg-indigo-700 transition-colors"
            >
              View Challenge
            </a>
          </section>
        </main>
      );
  
      // Footer component with spread props
      const Footer = ({ authorName, ...rest }) => (
        <footer className="p-4 bg-gray-100 rounded-lg shadow-md text-center">
          <p className="text-gray-600">
            Copyright © {new Date().getFullYear()} {authorName}. All rights reserved.
          </p>
        </footer>
      );
  
      // App component with props spreading
      const App = () => {
        const data = {
          title: "30 Days of React",
          author: {
            name: "Asabeneh Yetayeh",
            createdAt: "October 1, 2020",
            location: "Finland", // Extra prop to demonstrate spreading
          },
          day: 5, // Example day in the challenge
          totalDays: 30,
          version: "1.0", // Extra prop to demonstrate spreading
        };
  
        return (
          <div className="min-h-screen bg-gray-50 flex flex-col">
            <div className="max-w-4xl mx-auto w-full flex-grow">
              <Header title={data.title} {...data.author} />
              <ProgressTracker day={data.day} totalDays={data.totalDays} {...data} />
              <Footer authorName={data.author.name} />
            </div>
          </div>
        );
      };
  
      // Usage: <App />
    `,
    relatedTerms: ["Props", "Destructuring", "Rest Operator"],
  },
  {
    term: "State",
    category: "Core Concepts",
    definition: "A private, mutable object managed within a component to store data that can change over time, triggering re-renders when updated.",
    explanation: "State adds interactivity to components. In your `Reply` component, `isLiked` state tracks whether a user has liked a reply, updating the UI instantly. State is managed with the `useState` hook in functional components, making it essential for dynamic features like liking or toggling.",
    useCase: "Tracking whether a comment is liked or managing a form’s input value.",
    example: `
      import React, { useState } from 'react'
      import { Heart } from 'lucide-react'

      const LikeButton = () => {
        const [isLiked, setIsLiked] = useState(false)
        return (
          <button
            className="flex items-center gap-2 px-4 py-2 rounded-lg bg-gray-100 hover:bg-gray-200 transition-colors"
            onClick={() => setIsLiked(!isLiked)}
          >
            <Heart
              className={\`w-5 h-5 \${isLiked ? 'fill-red-500 text-red-500' : 'text-gray-500'}\`}
            />
            {isLiked ? 'Liked' : 'Like'}
          </button>
        )
      }

      // Usage: <LikeButton />
    `,
    relatedTerms: ["useState", "Controlled Components", "Component Lifecycle"]
  },
  {
    term: "Virtual DOM",
    category: "Core Concepts",
    definition: "A lightweight, in-memory representation of the real DOM that React uses to optimize updates by comparing changes and applying only necessary modifications.",
    explanation: "The Virtual DOM improves performance by minimizing direct DOM manipulations. When your `Comments` component updates with a new comment, React compares the Virtual DOM with the previous version and updates only the changed parts, like adding a new comment element, rather than re-rendering everything.",
    useCase: "Efficiently updating the UI when a list of comments changes.",
    example: null, // Internal mechanism, no direct snippet
    explanationForNoExample: "This is an internal React feature managed automatically when state or props change.",
    relatedTerms: ["Reconciliation", "Render"]
  },
  {
    term: "Reconciliation",
    category: "Core Concepts",
    definition: "React’s process of comparing two Virtual DOM trees to determine the minimal set of changes needed to update the real DOM efficiently.",
    explanation: "Reconciliation ensures fast updates. In your `Posts` component, when a new post is added, React reconciles the new list with the old one, updating only the new post’s DOM node instead of re-rendering all posts, thanks to keys.",
    useCase: "Updating a list of posts without re-rendering unchanged items.",
    example: null, // Internal process
    explanationForNoExample: "This is handled internally by React when rendering lists or components.",
    relatedTerms: ["Virtual DOM", "Lists and Keys"]
  },
  {
    term: "Component Lifecycle",
    category: "Core Concepts",
    definition: "The phases a component undergoes—mounting (creation), updating (state/prop changes), and unmounting (removal)—managed via lifecycle methods or hooks.",
    explanation: "Lifecycle methods allow side effects at specific points. In `SinglePost`, `useEffect` fetches post data on mount, mimicking `componentDidMount`. This is crucial for initializing data or cleaning up resources.",
    useCase: "Fetching post data when a component mounts or cleaning up on unmount.",
    example: `
      import React, { useEffect, useState } from 'react'

      const DataLoader = () => {
        const [data, setData] = useState(null)
        useEffect(() => {
          fetch('https://jsonplaceholder.typicode.com/posts/1')
            .then(res => res.json())
            .then(data => setData(data.title))
          return () => console.log('Cleanup')
        }, [])
        return (
          <div className="p-4 bg-white rounded-lg shadow">
            {data || 'Loading...'}
          </div>
        )
      }

      // Usage: <DataLoader />
    `,
    relatedTerms: ["useEffect", "Mounting"]
  },
  {
    term: "Event Handling",
    category: "Core Concepts",
    definition: "React’s mechanism for responding to user interactions (e.g., clicks, inputs) using synthetic events for cross-browser consistency.",
    explanation: "Event handlers make your UI interactive. In `Comment`, clicking the heart icon triggers `likeComment`, updating the UI and sending an API request. Synthetic events wrap native events, ensuring uniform behavior.",
    useCase: "Handling a like button click in a comment.",
    example: `
      import React from 'react'
      import { Heart } from 'lucide-react'

      const LikeButton = () => {
        const handleClick = () => alert('Liked!')
        return (
          <button
            className="flex items-center gap-2 px-4 py-2 bg-gray-100 rounded-lg hover:bg-gray-200 transition-colors"
            onClick={handleClick}
          >
            <Heart className="w-5 h-5 text-gray-500" />
            Like
          </button>
        )
      }

      // Usage: <LikeButton />
    `,
    relatedTerms: ["Synthetic Events", "Controlled Components"]
  },
  {
    term: "Conditional Rendering",
    category: "Core Concepts",
    definition: "Rendering different UI elements based on conditions, often using if statements, ternaries, or logical && operators in JSX.",
    explanation: "Conditional rendering adapts the UI to state. In `Comments`, the `AddComment` component only renders if `user` exists, showing the input for logged-in users only, enhancing UX by hiding irrelevant elements.",
    useCase: "Showing a reply input only for logged-in users.",
    example: `
      import React from 'react'

      const UserStatus = ({ isLoggedIn }) => {
        return (
          <div className="p-4 bg-white rounded-lg shadow">
            {isLoggedIn ? (
              <p className="text-green-600">Welcome back!</p>
            ) : (
              <p className="text-red-600">Please log in.</p>
            )}
          </div>
        )
      }

      // Usage: <UserStatus isLoggedIn={true} />
    `,
    relatedTerms: ["JSX", "Components"]
  },
  {
    term: "Lists and Keys",
    category: "Core Concepts",
    definition: "Rendering multiple elements from an array, where keys (unique identifiers) help React efficiently update the DOM by tracking item identity.",
    explanation: "Keys optimize list updates. In `Posts`, each `Post` gets a `key={post.id}`, ensuring React only re-renders new or changed posts, not the entire list, improving performance.",
    useCase: "Rendering a list of posts or comments efficiently.",
    example: `
      import React from 'react'

      const TodoList = ({ todos }) => {
        return (
          <ul className="space-y-2 p-4 bg-white rounded-lg shadow">
            {todos.map(todo => (
              <li key={todo.id} className="text-gray-700">
                {todo.text}
              </li>
            ))}
          </ul>
        )
      }

      // Usage: <TodoList todos={[{ id: 1, text: 'Learn React' }, { id: 2, text: 'Build App' }]} />
    `,
    relatedTerms: ["Reconciliation", "Fragment"]
  },
  {
    term: "Composition",
    category: "Core Concepts",
    definition: "Building complex UIs by combining simpler components, often using the `children` prop to pass nested content.",
    explanation: "Composition promotes reusability. In your app, a `Card` component could wrap `Post` content, allowing flexible layouts without duplicating code, similar to how `Comments` nests `Comment`.",
    useCase: "Creating a reusable card wrapper for posts or comments.",
    example: `
      import React from 'react'

      const Card = ({ children }) => {
        return (
          <div className="p-6 bg-white rounded-lg shadow-md">
            {children}
          </div>
        )
      }

      const PostCard = () => (
        <Card>
          <h2 className="text-xl font-bold text-gray-900">Post Title</h2>
          <p className="text-gray-700 mt-2">Post content goes here.</p>
        </Card>
      )

      // Usage: <PostCard />
    `,
    relatedTerms: ["Components", "Props"]
  },
  {
    term: "Fragment",
    category: "Core Concepts",
    definition: "A React component or shorthand (`<>...</>`) that groups multiple elements without adding extra DOM nodes.",
    explanation: "Fragments avoid unnecessary wrappers. In `Comment`, you could use a fragment to return the comment and replies without an extra `div`, keeping the DOM cleaner.",
    useCase: "Returning multiple elements from a component without a wrapper.",
    example: `
      import React from 'react'

      const ItemGroup = () => {
        return (
          <>
            <h3 className="text-lg font-semibold text-gray-900">Item 1</h3>
            <p className="text-gray-700">Description 1</p>
          </>
        )
      }

      // Usage: <ItemGroup />
    `,
    relatedTerms: ["JSX", "Lists and Keys"]
  },
  {
    term: "Refs",
    category: "Core Concepts",
    definition: "A way to directly access DOM nodes or React elements, typically used for imperative tasks like focusing inputs or measuring elements.",
    explanation: "Refs provide direct DOM manipulation when needed. In your app, a ref could focus the `AddComment` input after mounting, improving usability, though your current components don’t use them yet.",
    useCase: "Focusing a comment input field when a reply button is clicked.",
    example: `
      import React, { useRef, useEffect } from 'react'

      const FocusInput = () => {
        const inputRef = useRef(null)
        useEffect(() => {
          inputRef.current.focus()
        }, [])
        return (
          <input
            ref={inputRef}
            className="p-2 border border-gray-300 rounded-lg w-full"
            placeholder="Type here..."
          />
        )
      }

      // Usage: <FocusInput />
    `,
    relatedTerms: ["useRef", "Uncontrolled Components"]
  },
  {
    term: "Context",
    category: "Core Concepts",
    definition: "A mechanism to share data (e.g., user info) across components without prop drilling, using providers and consumers.",
    explanation: "Context simplifies global state. In your `SinglePost` and `Comments`, `UserContext` provides the `user` object, avoiding passing it through every level, which is ideal for auth or theme data.",
    useCase: "Sharing the logged-in user’s data with all comment components.",
    example: `
      import React, { createContext, useContext } from 'react'

      const UserContext = createContext(null)

      const UserDisplay = () => {
        const user = useContext(UserContext)
        return (
          <div className="p-4 bg-white rounded-lg shadow">
            <p className="text-gray-800">
              {user ? \`Welcome, \${user.name}!\` : 'Not logged in'}
            </p>
          </div>
        )
      }

      const App = () => (
        <UserContext.Provider value={{ name: 'Alice' }}>
          <UserDisplay />
        </UserContext.Provider>
      )

      // Usage: <App />
    `,
    relatedTerms: ["useContext", "Prop Drilling"]
  },
  {
    term: "Portals",
    category: "Core Concepts",
    definition: "A method to render children into a DOM node outside the parent component’s hierarchy, useful for modals or overlays.",
    explanation: "Portals keep UI elements like modals outside the main app structure. In your app, a delete confirmation modal in `SinglePost` could use a portal to render at the root level, avoiding z-index issues.",
    useCase: "Rendering a delete confirmation modal for a post.",
    example: `
      import React from 'react'
      import ReactDOM from 'react-dom'

      const Modal = ({ isOpen, onClose }) => {
        if (!isOpen) return null
        return ReactDOM.createPortal(
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <p className="text-gray-800 mb-4">Are you sure?</p>
              <button
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                onClick={onClose}
              >
                Close
              </button>
            </div>
          </div>,
          document.getElementById('modal-root')
        )
      }

      // Usage: <Modal isOpen={true} onClose={() => setIsOpen(false)} />
      // Requires: <div id="modal-root"></div> in index.html
    `,
    relatedTerms: ["Refs", "DOM"]
  },
  {
    term: "Error Boundaries",
    category: "Core Concepts",
    definition: "Components that catch JavaScript errors in their child tree, log them, and display a fallback UI instead of crashing the app.",
    explanation: "Error boundaries enhance reliability. In your `Posts` component, an error boundary could catch a rendering error in a single `Post` and show a fallback, preventing the whole list from crashing.",
    useCase: "Handling a failed post render without breaking the app.",
    example: `
      import React from 'react'

      class ErrorBoundary extends React.Component {
        state = { hasError: false }
        static getDerivedStateFromError() {
          return { hasError: true }
        }
        render() {
          if (this.state.hasError) {
            return (
              <div className="p-4 bg-red-50 text-red-700 rounded-lg">
                Something went wrong.
              </div>
            )
          }
          return this.props.children
        }
      }

      const App = () => (
        <ErrorBoundary>
          <div className="p-4 bg-white rounded-lg shadow">
            {Math.random() > 0.5 ? 'Good' : throwError()}
          </div>
        </ErrorBoundary>
      )

      // Usage: <App />
    `,
    relatedTerms: ["Component Lifecycle", "Suspense"]
  },
  {
    term: "Higher-Order Components (HOC)",
    category: "Core Concepts",
    definition: "A function that takes a component and returns an enhanced version with additional props or behavior, reusing logic across components.",
    explanation: "HOCs encapsulate shared logic. In your app, an HOC could add authentication checks to `SinglePost`, redirecting unauthenticated users without duplicating code.",
    useCase: "Adding auth checks to multiple components.",
    example: `
      import React from 'react'

      const withAuth = (Component) => {
        return (props) => {
          const isLoggedIn = true // Replace with real auth check
          return isLoggedIn ? (
            <Component {...props} />
          ) : (
            <div className="p-4 bg-red-50 text-red-700 rounded-lg">
              Please log in.
            </div>
          )
        }
      }

      const ProtectedContent = () => (
        <p className="text-gray-800">Secret content</p>
      )

      const ProtectedComponent = withAuth(ProtectedContent)

      // Usage: <ProtectedComponent />
    `,
    relatedTerms: ["Render Props", "Composition"]
  },
  {
    term: "Render Props",
    category: "Core Concepts",
    definition: "A pattern where a component shares logic by passing a function as a prop, which the parent uses to render content with that logic.",
    explanation: "Render props share behavior flexibly. In `Comments`, a render prop could provide comment count logic to a parent, though hooks often replace this pattern now.",
    useCase: "Sharing mouse position or data fetching logic with a parent.",
    example: `
      import React, { useState } from 'react'

      const MouseTracker = ({ render }) => {
        const [position, setPosition] = useState({ x: 0, y: 0 })
        const handleMouseMove = (e) => setPosition({ x: e.clientX, y: e.clientY })
        return (
          <div
            className="p-4 bg-gray-100 rounded-lg"
            onMouseMove={handleMouseMove}
          >
            {render(position)}
          </div>
        )
      }

      const App = () => (
        <MouseTracker
          render={({ x, y }) => (
            <p className="text-gray-800">X: {x}, Y: {y}</p>
          )}
        />
      )

      // Usage: <App />
    `,
    relatedTerms: ["Higher-Order Components", "Hooks"]
  },
  {
    term: "Strict Mode",
    category: "Core Concepts",
    definition: "A development tool that activates extra checks and warnings for potential issues in your app, like deprecated APIs or unsafe practices.",
    explanation: "Strict Mode helps catch errors early. Wrapping your app in `<StrictMode>` during development would warn about unsafe lifecycle methods or missing keys in your `Posts` list.",
    useCase: "Identifying problematic code during development.",
    example: `
      import React from 'react'

      const App = () => (
        <React.StrictMode>
          <div className="p-4 bg-white rounded-lg shadow">
            <p className="text-gray-800">Hello, Strict Mode!</p>
          </div>
        </React.StrictMode>
      )

      // Usage: <App />
    `,
    relatedTerms: ["React DevTools", "Debugging"]
  },
  {
    term: "PropTypes",
    category: "Core Concepts",
    definition: "A type-checking library in React to validate the types of props passed to components, catching bugs during development.",
    explanation: "PropTypes ensure correct data usage. In `Post`, you could define `PropTypes` for `title` (string) and `likes` (array), alerting you if invalid data is passed.",
    useCase: "Validating that a post component receives a string title.",
    example: `
      import React from 'react'
      import PropTypes from 'prop-types'

      const Post = ({ title }) => (
        <div className="p-4 bg-white rounded-lg shadow">
          <h2 className="text-xl font-bold text-gray-900">{title}</h2>
        </div>
      )

      Post.propTypes = {
        title: PropTypes.string.isRequired
      }

      // Usage: <Post title="My Post" />
    `,
    relatedTerms: ["Props", "DefaultProps"]
  },
  {
    term: "DefaultProps",
    category: "Core Concepts",
    definition: "A property to set default values for props, ensuring components work even if certain props are omitted.",
    explanation: "DefaultProps provide fallbacks. In `Greeting`, a default `greeting` ensures it renders even without that prop, improving component robustness.",
    useCase: "Setting a default greeting if none is provided.",
    example: `
      import React from 'react'

      const Greeting = ({ name, greeting }) => (
        <p className="text-lg text-gray-800">
          {greeting}, {name}!
        </p>
      )

      Greeting.defaultProps = {
        greeting: 'Hello'
      }

      // Usage: <Greeting name="Alice" /> // Renders "Hello, Alice!"
    `,
    relatedTerms: ["Props", "PropTypes"]
  },
  {
    term: "Controlled Components",
    category: "Core Concepts",
    definition: "Form elements whose values are managed by React state, with changes handled via event handlers.",
    explanation: "Controlled components keep form data in sync with state. In your app, an `AddComment` input could be controlled to validate or format text as users type.",
    useCase: "Managing a comment input with real-time validation.",
    example: `
      import React, { useState } from 'react'

      const CommentInput = () => {
        const [text, setText] = useState('')
        return (
          <input
            value={text}
            onChange={(e) => setText(e.target.value)}
            className="p-2 border border-gray-300 rounded-lg w-full"
            placeholder="Write a comment..."
          />
        )
      }

      // Usage: <CommentInput />
    `,
    relatedTerms: ["State", "Event Handling"]
  },
  {
    term: "Uncontrolled Components",
    category: "Core Concepts",
    definition: "Form elements that maintain their own internal state, accessed via refs rather than React state.",
    explanation: "Uncontrolled components are simpler for one-off forms. In your app, a simple comment input could use a ref to grab its value on submit, avoiding state overhead.",
    useCase: "Grabbing a comment value only on form submission.",
    example: `
      import React, { useRef } from 'react'

      const CommentInput = () => {
        const inputRef = useRef(null)
        const handleSubmit = () => alert(inputRef.current.value)
        return (
          <div className="p-4 bg-white rounded-lg shadow">
            <input
              ref={inputRef}
              className="p-2 border border-gray-300 rounded-lg w-full"
              placeholder="Write a comment..."
            />
            <button
              className="mt-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              onClick={handleSubmit}
            >
              Submit
            </button>
          </div>
        )
      }

      // Usage: <CommentInput />
    `,
    relatedTerms: ["Refs", "Form Handling"]
  },
  {
    term: "Pure Component",
    category: "Core Concepts",
    definition: "A component that avoids re-rendering if its props and state haven’t changed, using a shallow comparison for optimization.",
    explanation: "Pure components boost performance. In your `Post` component, making it a `PureComponent` could prevent re-renders when props like `title` stay the same.",
    useCase: "Optimizing a static post display.",
    example: `
      import React, { PureComponent } from 'react'

      class PostDisplay extends PureComponent {
        render() {
          const { title } = this.props
          return (
            <div className="p-4 bg-white rounded-lg shadow">
              <h2 className="text-xl font-bold text-gray-900">{title}</h2>
            </div>
          )
        }
      }

      // Usage: <PostDisplay title="My Post" />
    `,
    relatedTerms: ["React.memo", "Performance"]
  },
  {
    term: "Suspense",
    category: "Core Concepts",
    definition: "A feature that lets components wait for asynchronous operations (e.g., data fetching) and show a fallback UI during loading.",
    explanation: "Suspense simplifies loading states. In `Posts`, you could lazy-load a `Post` component, showing a spinner until it’s ready, improving perceived performance.",
    useCase: "Displaying a loading spinner while a post loads.",
    example: `
      import React, { Suspense, lazy } from 'react'

      const LazyPost = lazy(() => import('./Post'))

      const App = () => (
        <Suspense fallback={<div className="p-4 text-gray-600">Loading...</div>}>
          <LazyPost title="Lazy Post" />
        </Suspense>
      )

      // Usage: <App />
      // Note: './Post' should export a default component
    `,
    relatedTerms: ["Lazy Loading", "Code Splitting"]
  },
  {
    term: "Lazy Loading",
    category: "Core Concepts",
    definition: "Delaying the loading of components until they’re needed, using `React.lazy` and `Suspense` to reduce initial bundle size.",
    explanation: "Lazy loading speeds up app startup. In your app, lazy-loading `Comments` could defer its code until a user views a post’s comments, optimizing load time.",
    useCase: "Loading comments only when a user clicks to view them.",
    example: `
      import React, { Suspense, lazy } from 'react'

      const LazyComments = lazy(() => import('./Comments'))

      const PostView = ({ showComments }) => (
        <div className="p-4 bg-white rounded-lg shadow">
          <p className="text-gray-800">Post content</p>
          {showComments && (
            <Suspense fallback={<div className="p-2 text-gray-600">Loading comments...</div>}>
              <LazyComments id="1" comments={[]} />
            </Suspense>
          )}
        </div>
      )

      // Usage: <PostView showComments={true} />
    `,
    relatedTerms: ["Suspense", "Code Splitting"]
  },
  {
    term: "Code Splitting",
    category: "Core Concepts",
    definition: "Splitting your app’s code into smaller bundles loaded on demand, reducing initial load time with tools like `React.lazy`.",
    explanation: "Code splitting enhances performance. In your app, splitting `SinglePost` from the main bundle could load it only when a user navigates to a post, keeping the homepage light.",
    useCase: "Loading a single post’s code only when visiting its page.",
    example: `
      import React, { Suspense, lazy } from 'react'

      const LazySinglePost = lazy(() => import('./SinglePost'))

      const App = ({ showPost }) => (
        <div className="p-4">
          {showPost ? (
            <Suspense fallback={<div className="p-4 text-gray-600">Loading post...</div>}>
              <LazySinglePost />
            </Suspense>
          ) : (
            <p className="text-gray-800">Home page</p>
          )}
        </div>
      )

      // Usage: <App showPost={true} />
    `,
    relatedTerms: ["Lazy Loading", "Bundle Size Optimization"]
  },
  {
    term: "Synthetic Events",
    category: "Core Concepts",
    definition: "A cross-browser wrapper around native DOM events, providing a consistent API for event handling in React.",
    explanation: "Synthetic events ensure uniform behavior. In `Reply`, the `onClick` for liking uses a synthetic event, guaranteeing it works consistently across browsers.",
    useCase: "Handling a click event on a like button reliably.",
    example: `
      import React from 'react'

      const ClickLogger = () => {
        const handleClick = (e) => console.log('Event type:', e.type)
        return (
          <button
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
            onClick={handleClick}
          >
            Click Me
          </button>
        )
      }

      // Usage: <ClickLogger /> // Logs "click" on click
    `,
    relatedTerms: ["Event Handling", "DOM"]
  },

  // Hooks
  {
    term: "useState",
    category: "Hooks",
    definition: "A hook that adds state to functional components, returning a state value and an updater function.",
    explanation: "useState enables dynamic UIs. In `Comment`, `useState` tracks `isLiked`, updating the heart icon when clicked, making state management simple and local.",
    useCase: "Tracking if a comment is liked by the user.",
    example: `
      import React, { useState } from 'react'
      import { Heart } from 'lucide-react'

      const LikeToggle = () => {
        const [isLiked, setIsLiked] = useState(false)
        return (
          <button
            className="flex items-center gap-2 px-4 py-2 bg-gray-100 rounded-lg hover:bg-gray-200 transition-colors"
            onClick={() => setIsLiked(!isLiked)}
          >
            <Heart
              className={\`w-5 h-5 \${isLiked ? 'fill-red-500 text-red-500' : 'text-gray-500'}\`}
            />
            {isLiked ? 'Liked' : 'Like'}
          </button>
        )
      }

      // Usage: <LikeToggle />
    `,
    relatedTerms: ["State", "useReducer"]
  },
  {
    term: "useEffect",
    category: "Hooks",
    definition: "A hook for handling side effects (e.g., data fetching, subscriptions) in functional components, replacing lifecycle methods.",
    explanation: "useEffect manages side effects. In `SinglePost`, it fetches post data on mount, ensuring the UI updates when data arrives, with cleanup to prevent memory leaks.",
    useCase: "Fetching post data when the component mounts.",
    example: `
      import React, { useEffect, useState } from 'react'

      const PostFetcher = ({ id }) => {
        const [post, setPost] = useState(null)
        useEffect(() => {
          fetch(\`https://jsonplaceholder.typicode.com/posts/\${id}\`)
            .then(res => res.json())
            .then(data => setPost(data))
          return () => console.log('Cleanup')
        }, [id])
        return (
          <div className="p-4 bg-white rounded-lg shadow">
            {post ? post.title : 'Loading...'}
          </div>
        )
      }

      // Usage: <PostFetcher id={1} />
    `,
    relatedTerms: ["Component Lifecycle", "useLayoutEffect"]
  },
  {
    term: "useContext",
    category: "Hooks",
    definition: "A hook that subscribes a component to a context, accessing shared data without prop drilling.",
    explanation: "useContext simplifies data access. In `Comment`, it retrieves the `user` from `UserContext`, enabling display of the user’s avatar without passing props through layers.",
    useCase: "Accessing the current user’s data in a comment component.",
    example: `
      import React, { createContext, useContext } from 'react'

      const UserContext = createContext(null)

      const UserInfo = () => {
        const user = useContext(UserContext)
        return (
          <div className="p-4 bg-white rounded-lg shadow">
            <p className="text-gray-800">
              {user ? \`Hello, \${user.name}!\` : 'No user'}
            </p>
          </div>
        )
      }

      const App = () => (
        <UserContext.Provider value={{ name: 'Bob' }}>
          <UserInfo />
        </UserContext.Provider>
      )

      // Usage: <App />
    `,
    relatedTerms: ["Context", "useReducer"]
  },
  {
    term: "useReducer",
    category: "Hooks",
    definition: "A hook for managing complex state logic with a reducer function, offering an alternative to \`useState\` for intricate state transitions.",
    explanation: "useReducer handles complex state. In \`Comments\`, it could manage a comment form’s state (text, submitting, error), providing a structured way to update multiple values.",
    useCase: "Managing a comment form’s state with multiple fields.",
    example: `
      import React, { useReducer } from 'react'

      const initialState = { text: '', isSubmitting: false }
      const reducer = (state, action) => {
        switch (action.type) {
          case 'SET_TEXT': return { ...state, text: action.payload }
          case 'SUBMIT': return { ...state, isSubmitting: true }
          default: return state
        }
      }

      const CommentForm = () => {
        const [state, dispatch] = useReducer(reducer, initialState)
        return (
          <div className="p-4 bg-white rounded-lg shadow">
            <input
              value={state.text}
              onChange={(e) => dispatch({ type: 'SET_TEXT', payload: e.target.value })}
              className="p-2 border border-gray-300 rounded-lg w-full"
              placeholder="Write a comment..."
              disabled={state.isSubmitting}
            />
            <button
              className="mt-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              onClick={() => dispatch({ type: 'SUBMIT' })}
            >
              {state.isSubmitting ? 'Submitting...' : 'Submit'}
            </button>
          </div>
        )
      }

      // Usage: <CommentForm />
    `,
    relatedTerms: ["useState", "Redux"]
  },
  {
    term: "useCallback",
    category: "Hooks",
    definition: "A hook that returns a memoized callback function, preventing recreation unless dependencies change, optimizing performance.",
    explanation: "useCallback stabilizes functions. In \`Comment\`, memoizing \`handleLikeComment\` prevents unnecessary re-renders of child components relying on it.",
    useCase: "Passing a stable callback to a child component for liking a comment.",
    example: `
      import React, { useState, useCallback } from 'react'
      import { Heart } from 'lucide-react'

      const LikeButton = ({ onLike }) => (
        <button
          className="flex items-center gap-2 px-4 py-2 bg-gray-100 rounded-lg hover:bg-gray-200"
          onClick={onLike}
        >
          <Heart className="w-5 h-5 text-gray-500" />
          Like
        </button>
      )

      const Parent = () => {
        const [count, setCount] = useState(0)
        const handleLike = useCallback(() => setCount(c => c + 1), [])
        return (
          <div className="p-4 bg-white rounded-lg shadow">
            <p className="text-gray-800">Likes: {count}</p>
            <LikeButton onLike={handleLike} />
          </div>
        )
      }

      // Usage: <Parent />
    `,
    relatedTerms: ["useMemo", "Memoization"]
  },
  {
    term: "useMemo",
    category: "Hooks",
    definition: "A hook that memoizes a value, recalculating it only when dependencies change, optimizing expensive computations.",
    explanation: "useMemo boosts performance. In \`Posts\`, you could memoize a filtered list of posts, preventing recalculation on every render unless the data changes.",
    useCase: "Memoizing a filtered list of posts to avoid recomputation.",
    example: `
      import React, { useMemo } from 'react'

      const PostList = ({ posts }) => {
        const visiblePosts = useMemo(() => {
          return posts.filter(post => post.visible)
        }, [posts])
        return (
          <ul className="space-y-2 p-4 bg-white rounded-lg shadow">
            {visiblePosts.map(post => (
              <li key={post.id} className="text-gray-700">{post.title}</li>
            ))}
          </ul>
        )
      }

      // Usage: <PostList posts={[{ id: 1, title: 'Post 1', visible: true }, { id: 2, title: 'Post 2', visible: false }]} />
    `,
    relatedTerms: ["useCallback", "Memoization"]
  },
  {
    term: "useRef",
    category: "Hooks",
    definition: "A hook that creates a mutable reference persisting across renders, often used to access DOM elements or store values.",
    explanation: "useRef is versatile. In \`SinglePost\`, a ref could track a DOM element’s height or store the previous post ID for comparison, without triggering re-renders.",
    useCase: "Focusing a comment input or storing a previous value.",
    example: `
      import React, { useRef, useEffect } from 'react'

      const AutoFocusInput = () => {
        const inputRef = useRef(null)
        useEffect(() => {
          inputRef.current.focus()
        }, [])
        return (
          <input
            ref={inputRef}
            className="p-2 border border-gray-300 rounded-lg w-full"
            placeholder="Type here..."
          />
        )
      }

      // Usage: <AutoFocusInput />
    `,
    relatedTerms: ["Refs", "useImperativeHandle"]
  },
  {
    term: "useImperativeHandle",
    category: "Hooks",
    definition: "A hook that customizes the ref value exposed to parent components, allowing controlled access to child methods.",
    explanation: "useImperativeHandle enhances ref usage. In `AddComment`, you could expose a `focus` method to the parent, letting `Comments` focus the input programmatically.",
    useCase: "Exposing an input’s focus method to a parent component.",
    example: `
      import React, { useRef, useImperativeHandle, forwardRef } from 'react'

      const CommentInput = forwardRef((props, ref) => {
        const inputRef = useRef(null)
        useImperativeHandle(ref, () => ({
          focus: () => inputRef.current.focus()
        }))
        return (
          <input
            ref={inputRef}
            className="p-2 border border-gray-300 rounded-lg w-full"
            placeholder="Write a comment..."
          />
        )
      })

      const Parent = () => {
        const inputRef = useRef(null)
        return (
          <div className="p-4 bg-white rounded-lg shadow">
            <button
              className="mb-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              onClick={() => inputRef.current.focus()}
            >
              Focus Input
            </button>
            <CommentInput ref={inputRef} />
          </div>
        )
      }

      // Usage: <Parent />
    `,
    relatedTerms: ["useRef", "Refs"]
  },
  {
    term: "useLayoutEffect",
    category: "Hooks",
    definition: "A hook like `useEffect` but runs synchronously after DOM mutations, ideal for layout-related side effects.",
    explanation: "useLayoutEffect ensures DOM updates before painting. In `Post`, it could measure an image’s height before rendering, avoiding layout shifts.",
    useCase: "Measuring a post’s cover image height before display.",
    example: `
      import React, { useRef, useLayoutEffect, useState } from 'react'

      const ImageMeasurer = () => {
        const imgRef = useRef(null)
        const [height, setHeight] = useState(0)
        useLayoutEffect(() => {
          setHeight(imgRef.current.offsetHeight)
        }, [])
        return (
          <div className="p-4 bg-white rounded-lg shadow">
            <img
              ref={imgRef}
              src="https://via.placeholder.com/150"
              alt="Placeholder"
              className="w-full rounded"
            />
            <p className="mt-2 text-gray-800">Height: {height}px</p>
          </div>
        )
      }

      // Usage: <ImageMeasurer />
    `,
    relatedTerms: ["useEffect", "DOM"]
  },
  {
    term: "useDebugValue",
    category: "Hooks",
    definition: "A hook to display custom labels for hooks in React DevTools, aiding debugging of custom hook logic.",
    explanation: "useDebugValue improves debugging. In a custom hook for `Comments`, it could label the comment count, making DevTools more informative.",
    useCase: "Labeling a custom hook’s state in DevTools.",
    example: `
      import React, { useState, useDebugValue } from 'react'

      const useCounter = (initial) => {
        const [count, setCount] = useState(initial)
        useDebugValue(\`Count: \${count}\`)
        return [count, () => setCount(c => c + 1)]
      }

      const Counter = () => {
        const [count, increment] = useCounter(0)
        return (
          <div className="p-4 bg-white rounded-lg shadow">
            <p className="text-gray-800">{count}</p>
            <button
              className="mt-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              onClick={increment}
            >
              Increment
            </button>
          </div>
        )
      }

      // Usage: <Counter /> // Check React DevTools for "Count: X"
    `,
    relatedTerms: ["React DevTools", "Custom Hooks"]
  },
  {
    term: "useTransition",
    category: "Hooks",
    definition: "A React 18 hook that marks updates as non-urgent, allowing the UI to remain responsive during heavy renders.",
    explanation: "useTransition prioritizes responsiveness. In `Posts`, filtering a large post list could use a transition to show stale data while updating, avoiding lag.",
    useCase: "Filtering posts without blocking the UI.",
    example: `
      import React, { useState, useTransition } from 'react'

      const PostFilter = ({ posts }) => {
        const [filter, setFilter] = useState('')
        const [isPending, startTransition] = useTransition()
        const filteredPosts = posts.filter(post => post.title.includes(filter))
        return (
          <div className="p-4 bg-white rounded-lg shadow">
            <input
              value={filter}
              onChange={(e) => startTransition(() => setFilter(e.target.value))}
              className="p-2 border border-gray-300 rounded-lg w-full mb-2"
              placeholder="Filter posts..."
            />
            <ul className="space-y-1">
              {filteredPosts.map(post => (
                <li key={post.id} className={\`text-gray-800 \${isPending ? 'opacity-50' : ''}\`}>
                  {post.title}
                </li>
              ))}
            </ul>
            {isPending && <p className="text-gray-600 mt-2">Filtering...</p>}
          </div>
        )
      }

      // Usage: <PostFilter posts={[{ id: 1, title: 'React Basics' }, { id: 2, title: 'Advanced JS' }]} />
    `,
    relatedTerms: ["Suspense", "React 18 Features"]
  },
  {
    term: "useDeferredValue",
    category: "Hooks",
    definition: "A React 18 hook that defers a value’s update, showing stale data while processing a new value, keeping the UI responsive.",
    explanation: "useDeferredValue enhances UX during updates. In `Comments`, deferring a search input value could prevent UI stuttering while filtering comments.",
    useCase: "Showing old comment data while filtering new results.",
    example: `
      import React, { useState, useDeferredValue } from 'react'

      const CommentSearch = ({ comments }) => {
        const [query, setQuery] = useState('')
        const deferredQuery = useDeferredValue(query)
        const filteredComments = comments.filter(c => c.content.includes(deferredQuery))
        return (
          <div className="p-4 bg-white rounded-lg shadow">
            <input
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              className="p-2 border border-gray-300 rounded-lg w-full mb-2"
              placeholder="Search comments..."
            />
            <ul className="space-y-1">
              {filteredComments.map(c => (
                <li key={c.id} className="text-gray-800">{c.content}</li>
              ))}
            </ul>
          </div>
        )
      }

      // Usage: <CommentSearch comments={[{ id: 1, content: 'Great post!' }, { id: 2, content: 'Thanks!' }]} />
    `,
    relatedTerms: ["useTransition", "React 18 Features"]
  },
  {
    term: "useId",
    category: "Hooks",
    definition: "A React 18 hook that generates unique, stable IDs for accessibility attributes across server and client rendering.",
    explanation: "useId ensures consistent IDs. In `AddComment`, it could generate an ID for a label-input pair, improving accessibility without manual ID management.",
    useCase: "Creating unique IDs for a comment form’s label and input.",
    example: `
      import React, { useId } from 'react'

      const CommentForm = () => {
        const id = useId()
        return (
          <div className="p-4 bg-white rounded-lg shadow">
            <label htmlFor={id} className="block text-gray-800 mb-1">
              Comment
            </label>
            <input
              id={id}
              className="p-2 border border-gray-300 rounded-lg w-full"
              placeholder="Write a comment..."
            />
          </div>
        )
      }

      // Usage: <CommentForm />
    `,
    relatedTerms: ["Accessibility", "Forms"]
  },
  {
    term: "useInsertionEffect",
    category: "Hooks",
    definition: "A specialized hook for CSS-in-JS libraries to inject styles synchronously before DOM mutations, rarely used outside styling libraries.",
    explanation: "useInsertionEffect is niche but ensures style precedence. In your app, it’s unlikely needed unless using a CSS-in-JS library requiring pre-render style injection.",
    useCase: "Injecting dynamic styles before rendering (CSS-in-JS specific).",
    example: `
      import React, { useInsertionEffect } from 'react'

      const StyleInjector = () => {
        useInsertionEffect(() => {
          const style = document.createElement('style')
          style.textContent = '.custom { color: blue; }'
          document.head.appendChild(style)
          return () => document.head.removeChild(style)
        }, [])
        return (
          <p className="custom p-4 bg-white rounded-lg shadow">
            Blue text
          </p>
        )
      }

      // Usage: <StyleInjector />
    `,
    relatedTerms: ["useEffect", "CSS-in-JS"]
  },
  {
    term: "useSyncExternalStore",
    category: "Hooks",
    definition: "A hook to subscribe to an external store, ensuring consistent state across server and client rendering, typically for third-party integration.",
    explanation: "useSyncExternalStore bridges external state. In your app, it could sync a Redux store with React, though your current Context-based approach doesn’t need it.",
    useCase: "Integrating an external state library with React.",
    example: `
      import React, { useSyncExternalStore } from 'react'

      const store = {
        state: { count: 0 },
        listeners: [],
        subscribe: (listener) => { store.listeners.push(listener); return () => store.listeners = store.listeners.filter(l => l !== listener) },
        getState: () => store.state,
        setCount: (count) => { store.state.count = count; store.listeners.forEach(l => l()) }
      }

      const Counter = () => {
        const count = useSyncExternalStore(store.subscribe, store.getState).count
        return (
          <div className="p-4 bg-white rounded-lg shadow">
            <p className="text-gray-800">Count: {count}</p>
            <button
              className="mt-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              onClick={() => store.setCount(count + 1)}
            >
              Increment
            </button>
          </div>
        )
      }

      // Usage: <Counter />
    `,
    relatedTerms: ["Redux", "Server-Side Rendering"]
  },
  {
    term: "Custom Hooks",
    category: "Hooks",
    definition: "User-defined hooks starting with 'use' that encapsulate reusable logic, leveraging other React hooks.",
    explanation: "Custom hooks abstract logic. In `Comments`, a `useComments` hook could manage fetching and updating comments, reusable across components.",
    useCase: "Encapsulating comment fetching and state logic.",
    example: `
      import React, { useState, useEffect } from 'react'

      const useComments = (postId) => {
        const [comments, setComments] = useState([])
        useEffect(() => {
          fetch(\`https://jsonplaceholder.typicode.com/posts/\${postId}/comments\`)
            .then(res => res.json())
            .then(data => setComments(data))
        }, [postId])
        return comments
      }

      const CommentList = ({ postId }) => {
        const comments = useComments(postId)
        return (
          <ul className="space-y-2 p-4 bg-white rounded-lg shadow">
            {comments.map(c => (
              <li key={c.id} className="text-gray-700">{c.body}</li>
            ))}
          </ul>
        )
      }

      // Usage: <CommentList postId={1} />
    `,
    relatedTerms: ["useState", "useEffect"]
  },

  // State Management (limiting to a few key terms for brevity)
  {
    term: "Redux",
    category: "State Management",
    definition: "A predictable state container for JavaScript apps, using a single store and actions/reducers for state updates.",
    explanation: "Redux centralizes state. In your app, Redux could manage all posts and comments globally, though your current Context approach is simpler for user data.",
    useCase: "Managing a global list of posts and their comments.",
    example: `
      import { createStore } from 'redux'

      const initialState = { likes: 0 }
      const reducer = (state = initialState, action) => {
        switch (action.type) {
          case 'LIKE': return { ...state, likes: state.likes + 1 }
          default: return state
        }
      }
      const store = createStore(reducer)

      const LikeCounter = () => (
        <div className="p-4 bg-white rounded-lg shadow">
          <p className="text-gray-800">Likes: {store.getState().likes}</p>
          <button
            className="mt-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            onClick={() => store.dispatch({ type: 'LIKE' })}
          >
            Like
          </button>
        </div>
      )

      // Usage: <LikeCounter /> // Requires Redux Provider setup
    `,
    relatedTerms: ["useReducer", "Middleware"]
  },
  {
    term: "Context API",
    category: "State Management",
    definition: "React’s built-in way to share state across components without prop drilling, using providers and consumers.",
    explanation: "The Context API is lightweight state management. In your `SinglePost`, it shares `user` data with `Comments` and `Reply`, avoiding prop passing.",
    useCase: "Sharing user authentication state across the app.",
    example: `
      import React, { createContext, useContext } from 'react'

      const AuthContext = createContext(null)

      const UserProfile = () => {
        const user = useContext(AuthContext)
        return (
          <div className="p-4 bg-white rounded-lg shadow">
            <p className="text-gray-800">
              {user ? \`Logged in as \${user.name}\` : 'Guest'}
            </p>
          </div>
        )
      }

      const App = () => (
        <AuthContext.Provider value={{ name: 'Alice' }}>
          <UserProfile />
        </AuthContext.Provider>
      )

      // Usage: <App />
    `,
    relatedTerms: ["useContext", "Props"]
  },

  // Add more categories as needed (e.g., Routing, Styling) if desired
  // State Management
  {
    term: "Flux Architecture",
    category: "State Management",
    definition: "A design pattern for managing application state with unidirectional data flow, using actions, dispatchers, stores, and views.",
    explanation: "Flux inspired Redux and ensures predictable state updates. In your app, Flux could manage the flow of post likes or comment additions, though your current Context-based approach is simpler. It separates concerns, making state changes traceable.",
    useCase: "Managing a complex flow of liking and commenting on posts.",
    example: null, // Conceptual pattern, no direct React code
    explanationForNoExample: "Flux is a pattern implemented by libraries like Redux, not a standalone React feature.",
    relatedTerms: ["Redux", "Actions"]
  },
  {
    term: "Redux",
    category: "State Management",
    definition: "A predictable state container for JavaScript apps, using a single store, actions, and reducers to manage state globally.",
    explanation: "Redux centralizes state management. In your `SinglePost`, it could store all posts, comments, and likes, allowing any component to access or update them via actions, though your current local state and Context suffice for smaller scale.",
    useCase: "Storing and updating a global list of posts and their interactions.",
    example: `
      import { createStore } from 'redux'

      const initialState = { posts: [] }
      const reducer = (state = initialState, action) => {
        switch (action.type) {
          case 'ADD_POST':
            return { ...state, posts: [...state.posts, action.payload] }
          default:
            return state
        }
      }
      const store = createStore(reducer)

      const PostList = () => {
        const posts = store.getState().posts
        return (
          <ul className="space-y-2 p-4 bg-white rounded-lg shadow">
            {posts.map((post, index) => (
              <li key={index} className="text-gray-700">{post.title}</li>
            ))}
            <button
              className="mt-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              onClick={() => store.dispatch({ type: 'ADD_POST', payload: { title: 'New Post' } })}
            >
              Add Post
            </button>
          </ul>
        )
      }

      // Usage: <PostList /> // Requires Redux Provider setup
    `,
    relatedTerms: ["useReducer", "Middleware"]
  },
  {
    term: "Context API",
    category: "State Management",
    definition: "React’s built-in solution for sharing state across components without prop drilling, using providers and consumers.",
    explanation: "The Context API is ideal for lightweight global state. In your `Comments`, it shares the `user` object with `Comment` and `Reply`, avoiding repetitive prop passing, perfect for auth or theme data in your app.",
    useCase: "Sharing user data with all comment-related components.",
    example: `
      import React, { createContext, useContext } from 'react'

      const UserContext = createContext(null)

      const Comment = () => {
        const user = useContext(UserContext)
        return (
          <div className="p-4 bg-white rounded-lg shadow">
            <p className="text-gray-800">
              {user ? \`Comment by \${user.name}\` : 'Anonymous'}
            </p>
          </div>
        )
      }

      const App = () => (
        <UserContext.Provider value={{ name: 'Alice' }}>
          <Comment />
        </UserContext.Provider>
      )

      // Usage: <App />
    `,
    relatedTerms: ["useContext", "Props"]
  },

  // Routing
  {
    term: "React Router",
    category: "Routing",
    definition: "A library for routing in React apps, managing navigation, URL changes, and browser history.",
    explanation: "React Router enables page navigation. In `SinglePost`, `useParams` extracts the post ID from the URL, and `useNavigate` redirects after deletion, making your app a single-page application (SPA) with dynamic routing.",
    useCase: "Navigating between a post list and a single post view.",
    example: `
      import React from 'react'
      import { BrowserRouter, Routes, Route, Link, useParams } from 'react-router-dom'

      const Post = () => {
        const { id } = useParams()
        return (
          <div className="p-4 bg-white rounded-lg shadow">
            <h2 className="text-xl font-bold text-gray-900">Post {id}</h2>
          </div>
        )
      }

      const App = () => (
        <BrowserRouter>
          <nav className="p-4 bg-gray-100">
            <Link to="/post/1" className="text-blue-500 hover:underline">Post 1</Link>
          </nav>
          <Routes>
            <Route path="/post/:id" element={<Post />} />
          </Routes>
        </BrowserRouter>
      )

      // Usage: <App />
    `,
    relatedTerms: ["Routes", "Link Component"]
  },
  {
    term: "Protected Routes",
    category: "Routing",
    definition: "Routes that restrict access based on conditions (e.g., authentication), redirecting users if conditions aren’t met.",
    explanation: "Protected routes secure areas. In your app, you could protect the `SinglePost` edit route, redirecting unauthenticated users to a login page, aligning with your admin check logic.",
    useCase: "Restricting post editing to authenticated admins.",
    example: `
      import React from 'react'
      import { Navigate } from 'react-router-dom'

      const ProtectedRoute = ({ user, children }) => {
        return user ? (
          children
        ) : (
          <Navigate to="/login" replace />
        )
      }

      const EditPost = () => (
        <div className="p-4 bg-white rounded-lg shadow">
          <h2 className="text-xl font-bold text-gray-900">Edit Post</h2>
        </div>
      )

      const App = ({ user }) => (
        <ProtectedRoute user={user}>
          <EditPost />
        </ProtectedRoute>
      )

      // Usage: <App user={{ role: 'Admin' }} />
    `,
    relatedTerms: ["Navigate", "React Router"]
  },

  // Styling
  {
    term: "Tailwind CSS",
    category: "Styling",
    definition: "A utility-first CSS framework that uses predefined classes to style elements directly in JSX, eliminating custom CSS.",
    explanation: "Tailwind powers your app’s styling. In `Post`, classes like `bg-white`, `rounded-lg`, and `shadow` create a clean, consistent look without SCSS, speeding up development and ensuring responsiveness.",
    useCase: "Styling a post card with a shadow and rounded corners.",
    example: `
      import React from 'react'

      const PostCard = ({ title }) => (
        <div className="p-6 bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow">
          <h2 className="text-xl font-bold text-gray-900">{title}</h2>
          <p className="mt-2 text-gray-700">Post content here.</p>
        </div>
      )

      // Usage: <PostCard title="My Post" />
    `,
    relatedTerms: ["CSS Modules", "Inline Styles"]
  },
  {
    term: "CSS-in-JS",
    category: "Styling",
    definition: "A styling approach where CSS is written in JavaScript, often scoped to components, enabling dynamic styles.",
    explanation: "CSS-in-JS offers flexibility. In your app, you could use it to dynamically style a `Comment` based on state (e.g., liked status), though Tailwind suffices for your current needs.",
    useCase: "Dynamically styling a comment based on its like status.",
    example: `
      import React, { useState } from 'react'
      import styled from 'styled-components'

      const CommentBox = styled.div\`
        padding: 1rem;
        background-color: \${props => props.isLiked ? '#fef2f2' : '#fff'};
        border-radius: 0.5rem;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
      \`

      const Comment = () => {
        const [isLiked, setIsLiked] = useState(false)
        return (
          <CommentBox isLiked={isLiked}>
            <p className="text-gray-800">Nice post!</p>
            <button
              className="mt-2 px-2 py-1 bg-blue-500 text-white rounded hover:bg-blue-600"
              onClick={() => setIsLiked(!isLiked)}
            >
              {isLiked ? 'Unlike' : 'Like'}
            </button>
          </CommentBox>
        )
      }

      // Usage: <Comment /> // Requires styled-components
    `,
    relatedTerms: ["Styled Components", "Emotion"]
  },

  // Forms
  {
    term: "Form Handling",
    category: "Forms",
    definition: "Managing form state, validation, and submission in React, typically via controlled or uncontrolled components.",
    explanation: "Form handling captures user input. In `AddComment`, you handle input state and submission, sending new comments to the API, ensuring a smooth UX with validation or error feedback.",
    useCase: "Capturing and submitting a new comment.",
    example: `
      import React, { useState } from 'react'

      const CommentForm = ({ onSubmit }) => {
        const [text, setText] = useState('')
        const handleSubmit = (e) => {
          e.preventDefault()
          if (text.trim()) onSubmit(text)
          setText('')
        }
        return (
          <form onSubmit={handleSubmit} className="p-4 bg-white rounded-lg shadow">
            <input
              value={text}
              onChange={(e) => setText(e.target.value)}
              className="p-2 border border-gray-300 rounded-lg w-full mb-2"
              placeholder="Write a comment..."
            />
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              Submit
            </button>
          </form>
        )
      }

      // Usage: <CommentForm onSubmit={(text) => console.log(text)} />
    `,
    relatedTerms: ["Controlled Components", "Form Validation"]
  },
  {
    term: "React Hook Form",
    category: "Forms",
    definition: "A performant library for form management in React, using uncontrolled components and minimal re-renders.",
    explanation: "React Hook Form simplifies forms. In `AddComment`, it could reduce state management overhead, handling validation and submission efficiently with less code.",
    useCase: "Managing a comment form with validation.",
    example: `
      import React from 'react'
      import { useForm } from 'react-hook-form'

      const CommentForm = ({ onSubmit }) => {
        const { register, handleSubmit, reset } = useForm()
        const onFormSubmit = (data) => {
          onSubmit(data.comment)
          reset()
        }
        return (
          <form onSubmit={handleSubmit(onFormSubmit)} className="p-4 bg-white rounded-lg shadow">
            <input
              {...register('comment', { required: true })}
              className="p-2 border border-gray-300 rounded-lg w-full mb-2"
              placeholder="Write a comment..."
            />
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              Submit
            </button>
          </form>
        )
      }

      // Usage: <CommentForm onSubmit={(text) => console.log(text)} /> // Requires react-hook-form
    `,
    relatedTerms: ["Form Handling", "Uncontrolled Components"]
  },

  // Testing
  {
    term: "Jest",
    category: "Testing",
    definition: "A JavaScript testing framework focused on simplicity, widely used for unit testing React components.",
    explanation: "Jest tests your logic. In `Comment`, you could test that clicking the like button calls `likeComment`, ensuring functionality without a browser.",
    useCase: "Testing that a like button triggers the correct action.",
    example: `
      // Comment.test.js
      import { render, screen, fireEvent } from '@testing-library/react'
      import Comment from './Comment'

      test('calls likeComment on button click', () => {
        const likeComment = jest.fn()
        render(<Comment comment={{ _id: '1', content: 'Test', likes: [] }} likeComment={likeComment} />)
        fireEvent.click(screen.getByRole('button', { name: /like/i }))
        expect(likeComment).toHaveBeenCalledWith('1')
      })
    `,
    relatedTerms: ["React Testing Library", "Snapshot Testing"]
  },
  {
    term: "React Testing Library",
    category: "Testing",
    definition: "A testing utility that encourages testing components from a user’s perspective, focusing on behavior over implementation.",
    explanation: "RTL tests UX. In `SinglePost`, you could test that the edit button appears for admins, simulating real user interactions.",
    useCase: "Testing that an edit button is visible only to admins.",
    example: `
      // SinglePost.test.js
      import { render, screen } from '@testing-library/react'
      import SinglePost from './SinglePost'
      import { UserContext } from './context/UserContext'

      test('shows edit button for admin', () => {
        const user = { role: ['Admin'], _id: '1' }
        render(
          <UserContext.Provider value={{ user }}>
            <SinglePost post={{ user: { _id: '1' }, title: 'Test' }} />
          </UserContext.Provider>
        )
        expect(screen.getByText('Edit')).toBeInTheDocument()
      })
    `,
    relatedTerms: ["Jest", "Enzyme"]
  },

  // Performance
  {
    term: "Memoization",
    category: "Performance",
    definition: "Caching results of expensive operations to avoid recomputation, implemented via `useMemo` and `useCallback` in React.",
    explanation: "Memoization optimizes performance. In `Comments`, memoizing a comment list filter prevents recalculating unless comments change, reducing render cost.",
    useCase: "Memoizing a filtered comment list.",
    example: `
      import React, { useMemo } from 'react'

      const CommentList = ({ comments, filter }) => {
        const filteredComments = useMemo(() => {
          return comments.filter(c => c.content.includes(filter))
        }, [comments, filter])
        return (
          <ul className="space-y-2 p-4 bg-white rounded-lg shadow">
            {filteredComments.map(c => (
              <li key={c.id} className="text-gray-700">{c.content}</li>
            ))}
          </ul>
        )
      }

      // Usage: <CommentList comments={[{ id: 1, content: 'Hi' }]} filter="Hi" />
    `,
    relatedTerms: ["useMemo", "useCallback"]
  },
  {
    term: "React.memo",
    category: "Performance",
    definition: "A higher-order component that prevents re-renders if props haven’t changed, optimizing functional components.",
    explanation: "React.memo reduces unnecessary renders. In `Reply`, wrapping it with `memo` could prevent re-renders when only the parent `Comment` updates, improving performance.",
    useCase: "Preventing a reply from re-rendering when its props are unchanged.",
    example: `
      import React, { memo } from 'react'
      import { Heart } from 'lucide-react'

      const Reply = memo(({ content }) => (
        <div className="p-4 bg-gray-50 rounded-lg shadow">
          <p className="text-gray-700">{content}</p>
          <Heart className="w-5 h-5 text-gray-500 mt-2" />
        </div>
      ))

      const Parent = () => {
        const [count, setCount] = React.useState(0)
        return (
          <div className="p-4">
            <button
              className="mb-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              onClick={() => setCount(c => c + 1)}
            >
              {count}
            </button>
            <Reply content="Static Reply" />
          </div>
        )
      }

      // Usage: <Parent /> // Reply won’t re-render on count change
    `,
    relatedTerms: ["Pure Component", "Memoization"]
  },

  // Server-Side Rendering & Static Generation
  {
    term: "Next.js",
    category: "Server-Side Rendering",
    definition: "A React framework for server-side rendering, static site generation, and simplified routing.",
    explanation: "Next.js enhances React apps. Your `SinglePost` could use SSR to pre-render post data for SEO, or SSG for static blog pages, improving load times and searchability.",
    useCase: "Pre-rendering a post page for better SEO.",
    example: `
      // pages/posts/[id].js
      import React from 'react'

      const PostPage = ({ post }) => (
        <div className="p-4 bg-white rounded-lg shadow">
          <h1 className="text-2xl font-bold text-gray-900">{post.title}</h1>
          <p className="mt-2 text-gray-700">{post.content}</p>
        </div>
      )

      export async function getServerSideProps({ params }) {
        const res = await fetch(\`https://jsonplaceholder.typicode.com/posts/\${params.id}\`)
        const post = await res.json()
        return { props: { post } }
      }

      export default PostPage

      // Usage: Navigate to /posts/1
    `,
    relatedTerms: ["Server-Side Rendering", "Static Site Generation"]
  },
  {
    term: "Server-Side Rendering (SSR)",
    category: "Server-Side Rendering",
    definition: "Rendering React components on the server to HTML, sent to the client for faster initial loads and better SEO.",
    explanation: "SSR improves first paint. In your app, SSR could render `SinglePost` server-side, sending HTML with post data, then hydrating it client-side for interactivity.",
    useCase: "Rendering a post page server-side for SEO.",
    example: `
      // See Next.js example above for practical SSR
    `,
    relatedTerms: ["Next.js", "Hydration"]
  },

  // Modern React Features
  {
    term: "React 18 Features",
    category: "Modern Features",
    definition: "New capabilities in React 18, including automatic batching, concurrent rendering, transitions, and suspense enhancements.",
    explanation: "React 18 boosts performance. In `Comments`, automatic batching groups state updates, and `useTransition` could keep filtering responsive, enhancing UX.",
    useCase: "Improving responsiveness during comment filtering.",
    example: `
      import React, { useState, useTransition } from 'react'

      const CommentFilter = ({ comments }) => {
        const [filter, setFilter] = useState('')
        const [isPending, startTransition] = useTransition()
        const filteredComments = comments.filter(c => c.content.includes(filter))
        return (
          <div className="p-4 bg-white rounded-lg shadow">
            <input
              value={filter}
              onChange={(e) => startTransition(() => setFilter(e.target.value))}
              className="p-2 border border-gray-300 rounded-lg w-full mb-2"
              placeholder="Filter comments..."
            />
            <ul className="space-y-1">
              {filteredComments.map(c => (
                <li key={c.id} className={\`text-gray-700 \${isPending ? 'opacity-50' : ''}\`}>
                  {c.content}
                </li>
              ))}
            </ul>
          </div>
        )
      }

      // Usage: <CommentFilter comments={[{ id: 1, content: 'Great post!' }]} />
    `,
    relatedTerms: ["useTransition", "Suspense"]
  },
  {
    term: "Suspense for Data Fetching",
    category: "Modern Features",
    definition: "Using Suspense to wait for data fetching, showing a fallback UI until data is ready.",
    explanation: "Suspense streamlines loading. In `SinglePost`, it could wrap a data fetch, showing a loader until the post loads, simplifying async handling.",
    useCase: "Showing a loader while fetching post data.",
    example: `
      import React, { Suspense } from 'react'

      const fetchPost = (id) => new Promise(resolve => setTimeout(() => resolve({ title: 'Post' }), 1000))

      const PostData = ({ id }) => {
        const post = fetchPost(id) // Simplified; use real fetch with Suspense-compatible library
        return (
          <div className="p-4 bg-white rounded-lg shadow">
            <h2 className="text-xl font-bold text-gray-900">{post.title}</h2>
          </div>
        )
      }

      const App = () => (
        <Suspense fallback={<div className="p-4 text-gray-600">Loading...</div>}>
          <PostData id={1} />
        </Suspense>
      )

      // Usage: <App /> // Note: Requires a Suspense-ready data fetching library
    `,
    relatedTerms: ["Suspense", "React Query"]
  },

  // Data Fetching
  {
    term: "Axios",
    category: "Data Fetching",
    definition: "A promise-based HTTP client for making API requests, with features like automatic JSON parsing and error handling.",
    explanation: "Axios simplifies API calls. In `SinglePost`, it fetches post data cleanly, handling errors and headers, making your async code more robust than fetch.",
    useCase: "Fetching a single post’s data from an API.",
    example: `
      import React, { useState, useEffect } from 'react'
      import axios from 'axios'

      const PostFetcher = ({ id }) => {
        const [post, setPost] = useState(null)
        useEffect(() => {
          axios.get(\`https://jsonplaceholder.typicode.com/posts/\${id}\`)
            .then(response => setPost(response.data))
            .catch(() => setPost({ title: 'Error' }))
        }, [id])
        return (
          <div className="p-4 bg-white rounded-lg shadow">
            {post ? post.title : 'Loading...'}
          </div>
        )
      }

      // Usage: <PostFetcher id={1} />
    `,
    relatedTerms: ["Fetch API", "useEffect"]
  },
  {
    term: "React Query",
    category: "Data Fetching",
    definition: "A library for managing, caching, and syncing server state in React, simplifying data fetching.",
    explanation: "React Query handles server state. In `Comments`, it could fetch and cache comments, auto-refetching when needed, reducing manual state management.",
    useCase: "Fetching and caching post comments.",
    example: `
      import React from 'react'
      import { useQuery } from '@tanstack/react-query'

      const fetchComments = async (id) => {
        const res = await fetch(\`https://jsonplaceholder.typicode.com/posts/\${id}/comments\`)
        return res.json()
      }

      const CommentList = ({ postId }) => {
        const { data, isLoading } = useQuery(['comments', postId], () => fetchComments(postId))
        return (
          <div className="p-4 bg-white rounded-lg shadow">
            {isLoading ? (
              <p className="text-gray-600">Loading...</p>
            ) : (
              <ul className="space-y-1">
                {data.map(c => (
                  <li key={c.id} className="text-gray-700">{c.body}</li>
                ))}
              </ul>
            )}
          </div>
        )
      }

      // Usage: <CommentList postId={1} /> // Requires React Query setup
    `,
    relatedTerms: ["SWR", "useEffect"]
  },

  // Build Tools
  {
    term: "Webpack",
    category: "Build Tools",
    definition: "A module bundler that processes JavaScript, CSS, and other assets into optimized bundles for deployment.",
    explanation: "Webpack bundles your app. It compiles your `SinglePost` JSX and dependencies into a production-ready bundle, though tools like Vite or CRA often abstract it.",
    useCase: "Bundling your React app for production.",
    example: null, // Configuration-based
    explanationForNoExample: "Webpack is configured via a webpack.config.js file, not directly coded in components.",
    relatedTerms: ["Babel", "Tree Shaking"]
  },
  {
    term: "Vite",
    category: "Build Tools",
    definition: "A fast build tool using native ES modules for development and ESBuild for production, offering quick startups.",
    explanation: "Vite speeds up development. Your app could use Vite for instant hot module replacement (HMR) when editing `Post`, improving dev experience over Webpack.",
    useCase: "Fast development with live reloading.",
    example: `
      // vite.config.js
      import { defineConfig } from 'vite'
      import react from '@vitejs/plugin-react'

      export default defineConfig({
        plugins: [react()]
      })

      // Run: vite // Starts dev server
    `,
    relatedTerms: ["ESBuild", "Webpack"]
  },

  // Ecosystem & Meta-Frameworks
  {
    term: "TypeScript with React",
    category: "Ecosystem",
    definition: "Using TypeScript, a typed superset of JavaScript, with React for static type checking and better tooling.",
    explanation: "TypeScript adds safety. In `SinglePost`, typing the `post` prop ensures it has `title` and `likes`, catching errors early during development.",
    useCase: "Typing a post component’s props for safety.",
    example: `
      import React from 'react'

      interface PostProps {
        title: string
        likes: number[]
      }

      const Post: React.FC<PostProps> = ({ title, likes }) => (
        <div className="p-4 bg-white rounded-lg shadow">
          <h2 className="text-xl font-bold text-gray-900">{title}</h2>
          Likes: {likes.length}
        </div>
      )

      // Usage: <Post title="My Post" likes={[1, 2, 3]} />
    `,
    relatedTerms: ["Props", "Components"]
  },
  {
    term: "Framer Motion",
    category: "Ecosystem",
    definition: "A motion library for React to create smooth animations and gestures with a declarative API.",
    explanation: "Framer Motion adds flair. In `Comment`, you could animate the like button’s appearance, enhancing UX with a subtle scale effect.",
    useCase: "Animating a like button on click.",
    example: `
      import React, { useState } from 'react'
      import { motion } from 'framer-motion'

      const LikeButton = () => {
        const [isLiked, setIsLiked] = useState(false)
        return (
          <motion.button
            className="flex items-center gap-2 px-4 py-2 bg-gray-100 rounded-lg"
            onClick={() => setIsLiked(!isLiked)}
            whileTap={{ scale: 0.9 }}
            animate={{ scale: isLiked ? 1.1 : 1 }}
          >
            <Heart className={\`w-5 h-5 \${isLiked ? 'fill-red-500 text-red-500' : 'text-gray-500'}\`} />
            {isLiked ? 'Liked' : 'Like'}
          </motion.button>
        )
      }

      // Usage: <LikeButton /> // Requires framer-motion
    `,
    relatedTerms: ["React Spring", "Animations"]
  }
]

export default reactGlossary