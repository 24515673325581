import React, { useState } from 'react';
import { Navigate, Link } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Mail } from 'lucide-react';

const ForgotPasswordPage = () => {
  const initialState = {
    email: '',
  };

  const [formData, setFormData] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState(false);

  const onChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const url = '/api/users/forgot-password'; // Assumed API endpoint
      const response = await axios.post(url, { email: formData.email });
      setLoading(false);
      setSuccess(true);
      toast.success('Password reset link sent! Check your email.');
      setTimeout(() => setRedirect(true), 3000); // Redirect after 3 seconds
    } catch (error) {
      setLoading(false);
      setErrors(error.response?.data || { email: 'Something went wrong' });
      console.log('Forgot Password error', error);
      toast.error('Failed to send reset link. Please try again.');
    }
  };

  if (redirect) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-indigo-600 via-purple-500 to-pink-500 p-4">
      <div className="bg-white rounded-xl shadow-2xl p-8 w-full max-w-lg transform transition-all hover:scale-105">
        <form onSubmit={onSubmit} className="space-y-6">
          {/* Header */}
          <div className="text-center">
            <h1 className="text-3xl font-bold text-gray-900 mb-2">Reset Your Password</h1>
            <p className="text-sm text-gray-500">
              Enter your email to receive a password reset link.
            </p>
          </div>

          {/* Email Input */}
          <div className="relative">
            <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
              Email Address
            </label>
            <div className="flex items-center border rounded-lg overflow-hidden focus-within:ring-2 focus-within:ring-indigo-400 transition-all">
              <span className="pl-3 text-gray-500">
                <Mail size={20} />
              </span>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={onChange}
                placeholder="Your email"
                className={`w-full py-3 px-3 border-0 focus:outline-none ${errors.email ? 'text-red-600' : 'text-gray-900'}`}
                aria-label="Email Address"
                disabled={success} // Disable input after success
              />
            </div>
            {errors.email && !success && <p className="text-red-500 text-xs mt-1">{errors.email}</p>}
            {success && (
              <p className="text-green-600 text-xs mt-1">Reset link sent successfully!</p>
            )}
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            disabled={loading || success}
            className={`w-full py-3 bg-indigo-600 text-white rounded-lg font-semibold hover:bg-indigo-700 focus:ring-4 focus:ring-indigo-300 transition-all duration-300 ease-in-out ${loading || success ? 'opacity-60 cursor-not-allowed' : ''}`}
          >
            {loading ? (
              <span className="flex items-center justify-center">
                <svg className="animate-spin h-5 w-5 mr-2 text-white" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none" />
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8h8a8 8 0 01-16 0z" />
                </svg>
                Sending...
              </span>
            ) : success ? (
              'Link Sent'
            ) : (
              'Send Reset Link'
            )}
          </button>

          {/* Back to Login Link */}
          <div className="text-center text-sm text-gray-600">
            Remembered your password?{' '}
            <Link to="/login" className="text-indigo-600 font-medium hover:underline hover:text-indigo-800 transition-colors">
              Log In
            </Link>
          </div>
        </form>

        {/* Toast Notifications */}
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </div>
  );
};

export default ForgotPasswordPage;