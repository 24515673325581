// src/components/ReactCheatSheet.jsx
import React, { useState } from 'react';
import { reactGlossary } from '../utils/reactGlossary'; // Assuming this is your glossary file
import { Search, Copy, ChevronDown, ChevronUp } from 'lucide-react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneLight } from 'react-syntax-highlighter/dist/esm/styles/prism';

const ReactCheatSheet = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [expandedCategories, setExpandedCategories] = useState({});
  const [copiedStates, setCopiedStates] = useState({});

  // Group glossary items by category
  const groupedGlossary = reactGlossary.reduce((acc, item) => {
    acc[item.category] = acc[item.category] || [];
    acc[item.category].push(item);
    return acc;
  }, {});

  // Filter categories based on search term
  const filteredCategories = Object.keys(groupedGlossary)
    .filter((category) =>
      groupedGlossary[category].some(
        (item) =>
          item.term.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.definition.toLowerCase().includes(searchTerm.toLowerCase())
      )
    )
    .reduce((acc, category) => {
      acc[category] = groupedGlossary[category].filter(
        (item) =>
          item.term.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.definition.toLowerCase().includes(searchTerm.toLowerCase())
      );
      return acc;
    }, {});

  // Toggle category expansion
  const toggleCategory = (category) => {
    setExpandedCategories((prev) => ({
      ...prev,
      [category]: !prev[category],
    }));
  };

  // Copy code to clipboard
  const handleCopy = (code, term, index) => {
    navigator.clipboard.writeText(code).then(() => {
      setCopiedStates((prev) => ({
        ...prev,
        [`${term}-${index}`]: true,
      }));
      setTimeout(() => {
        setCopiedStates((prev) => ({
          ...prev,
          [`${term}-${index}`]: false,
        }));
      }, 2000); // Reset after 2 seconds
    });
  };

  return (
    <div className="min-h-screen bg-gray-50 text-gray-900 py-12 px-6 antialiased">
      <div className="max-w-5xl mx-auto">
        {/* Header */}
        <header className="text-center mb-12">
          <h1 className="text-4xl md:text-5xl font-bold text-indigo-900 mb-2">
            React Cheat Sheet
          </h1>
          <p className="text-lg text-gray-600 font-light">
            A comprehensive guide to React concepts, syntax, and examples
          </p>
        </header>

        {/* Search Bar */}
        <div className="relative mb-12">
          <input
            type="text"
            placeholder="Search React terms..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full p-4 pl-12 rounded-lg bg-white border border-gray-200 text-gray-800 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-400 focus:border-transparent shadow-md hover:shadow-lg transition-all duration-200"
          />
          <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-500" />
        </div>

        {/* Categories */}
        <div className="space-y-6">
          {Object.keys(filteredCategories).length === 0 ? (
            <p className="text-center text-gray-500 text-lg font-light">
              No matching terms found.
            </p>
          ) : (
            Object.entries(filteredCategories).map(([category, items]) => (
              <div
                key={category}
                className="bg-white rounded-lg shadow-md border border-gray-200 overflow-hidden"
              >
                {/* Category Header */}
                <button
                  onClick={() => toggleCategory(category)}
                  className="w-full px-6 py-4 flex justify-between items-center bg-indigo-50 hover:bg-indigo-100 transition-colors duration-200 text-left"
                >
                  <h2 className="text-2xl font-semibold text-indigo-800">
                    {category}
                  </h2>
                  {expandedCategories[category] ? (
                    <ChevronUp className="h-6 w-6 text-indigo-600" />
                  ) : (
                    <ChevronDown className="h-6 w-6 text-indigo-600" />
                  )}
                </button>

                {/* Category Content */}
                {expandedCategories[category] && (
                  <div className="p-6 space-y-6">
                    {items.map(({ term, definition, useCase, example, relatedTerms }) => (
                      <div key={term} className="space-y-4">
                        <h3 className="text-xl font-semibold text-indigo-700">{term}</h3>
                        <p className="text-base text-gray-700 leading-relaxed">{definition}</p>
                        {useCase && (
                          <p className="text-base text-gray-600">
                            <strong className="text-indigo-600">Use Case:</strong> {useCase}
                          </p>
                        )}
                        {example && (
                          <div>
                            {Array.isArray(example) ? (
                              example.map((ex, index) => (
                                <div key={index} className="relative mb-4 last:mb-0">
                                  <SyntaxHighlighter
                                    language="jsx"
                                    style={oneLight}
                                    customStyle={{
                                      borderRadius: '0.375rem',
                                      padding: '1rem 2.5rem 1rem 1rem',
                                      background: '#f8fafc',
                                      fontSize: '0.95rem',
                                      lineHeight: '1.5',
                                    }}
                                  >
                                    {ex}
                                  </SyntaxHighlighter>
                                  <button
                                    onClick={() => handleCopy(ex, term, index)}
                                    className="absolute top-2 right-2 w-8 h-8 flex items-center justify-center rounded-md bg-gray-200 hover:bg-gray-300 text-gray-700 transition-colors duration-200"
                                    title="Copy code"
                                  >
                                    <Copy className="w-4 h-4" />
                                    {copiedStates[`${term}-${index}`] && (
                                      <span className="absolute -top-8 left-1/2 transform -translate-x-1/2 bg-green-600 text-white text-xs px-2 py-1 rounded">
                                        Copied!
                                      </span>
                                    )}
                                  </button>
                                </div>
                              ))
                            ) : (
                              <div className="relative">
                                <SyntaxHighlighter
                                  language="jsx"
                                  style={oneLight}
                                  customStyle={{
                                    borderRadius: '0.375rem',
                                    padding: '1rem 2.5rem 1rem 1rem',
                                    background: '#f8fafc',
                                    fontSize: '0.95rem',
                                    lineHeight: '1.5',
                                  }}
                                >
                                  {example}
                                </SyntaxHighlighter>
                                <button
                                  onClick={() => handleCopy(example, term, 0)}
                                  className="absolute top-2 right-2 w-8 h-8 flex items-center justify-center rounded-md bg-gray-200 hover:bg-gray-300 text-gray-700 transition-colors duration-200"
                                  title="Copy code"
                                >
                                  <Copy className="w-4 h-4" />
                                  {copiedStates[`${term}-0`] && (
                                    <span className="absolute -top-8 left-1/2 transform -translate-x-1/2 bg-green-600 text-white text-xs px-2 py-1 rounded">
                                      Copied!
                                    </span>
                                  )}
                                </button>
                              </div>
                            )}
                          </div>
                        )}
                        {relatedTerms && (
                          <p className="text-base text-gray-600">
                            <strong className="text-indigo-600">Related Terms:</strong>{' '}
                            {relatedTerms.join(', ')}
                          </p>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default ReactCheatSheet;