import React, { useState, useContext } from 'react'
import { format } from 'date-fns'
import { UserContext } from '../../../context/UserContext'
import { Heart, MessageCircle } from 'lucide-react'
import AddReply from './AddReply'
import Reply from './Reply'

const Comment = ({ comment, addReply, likeComment, likeReply }) => {
  const { user } = useContext(UserContext)
  const [isLiked, setIsLiked] = useState(comment.likes.includes(user?._id))

  const handleLikeComment = () => {
    likeComment(comment._id)
    setIsLiked(!isLiked)
  }

  const formattedDate = format(new Date(comment.createdAt), 'MMM d, yyyy HH:mm')

  return (
    <div className="space-y-4">
      {/* Main Comment */}
      <div className="bg-white border border-gray-200 rounded-lg p-4 shadow-sm hover:shadow-md transition-shadow">
        <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-3">
          <div className="flex items-center gap-3">
            <img
              src={comment.user.avatar || '/default-avatar.png'}
              alt={`${comment.user.firstName}'s avatar`}
              className="w-10 h-10 rounded-full object-cover border border-gray-200"
              onError={(e) => (e.target.src = '/default-avatar.png')}
            />
            <div>
              <span className="font-medium text-gray-900">{comment.user.firstName}</span>
              <time className="block text-sm text-gray-500" dateTime={comment.createdAt}>
                {formattedDate}
              </time>
            </div>
          </div>

          <div className="flex items-center gap-4 text-gray-600">
            <button
              onClick={handleLikeComment}
              className="flex items-center gap-1 hover:text-red-500 transition-colors group"
            >
              <Heart
                className={`w-5 h-5 ${isLiked ? 'fill-red-500 text-red-500' : 'group-hover:fill-red-500 group-hover:text-red-500'}`}
              />
              <span>{comment.likes.length > 0 ? comment.likes.length : 0}</span>
            </button>
            <div className="flex items-center gap-1">
              <MessageCircle className="w-5 h-5" />
              <span>{comment.replies.length > 0 ? comment.replies.length : 0}</span>
            </div>
          </div>
        </div>

        <p className="mt-3 text-gray-700 leading-relaxed">{comment.content}</p>
      </div>

      {/* Replies */}
      {comment.replies.length > 0 && (
        <div className="ml-4 sm:ml-8 space-y-3">
          {comment.replies.map((reply) => (
            <Reply
              key={reply._id}
              reply={reply}
              likeReply={likeReply}
              commentId={comment._id}
            />
          ))}
        </div>
      )}

      {/* Reply Input (if user is logged in) */}
      {user && (
        <div className="bg-gray-50 border border-gray-200 rounded-lg p-4 mt-4">
          <div className="flex items-center gap-3 mb-3">
            <img
              src={user.avatar || '/default-avatar.png'}
              alt={`${user.firstName}'s avatar`}
              className="w-8 h-8 rounded-full object-cover border border-gray-200"
              onError={(e) => (e.target.src = '/default-avatar.png')}
            />
            <span className="font-medium text-gray-900">{user.firstName}</span>
          </div>
          <AddReply addReply={addReply} id={comment._id} />
        </div>
      )}
    </div>
  )
}

export default Comment