import React, { useEffect, useState, useContext } from 'react'
import Comment from './Comment'
import AddComment from './AddComment'
import axios from 'axios'
import { UserContext } from '../../../context/UserContext'
import { AlertCircle } from 'lucide-react'

const Comments = ({ id, comments: initialComments }) => {
  const { user } = useContext(UserContext)
  const [comments, setComments] = useState(initialComments || [])
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  // Sync comments when initialComments prop changes
  useEffect(() => {
    setComments(initialComments || [])
  }, [initialComments])

  const addComment = async (content) => {
    try {
      setLoading(true)
      setError(null)
      const response = await axios.post(
        `/api/posts/${id}/comments`,
        { content },
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        }
      )
      setComments((prev) => [response.data, ...prev])
    } catch (error) {
      setError('Failed to add comment. Please try again.')
      console.error('Error adding comment:', error)
    } finally {
      setLoading(false)
    }
  }

  const addReply = async (commentId, content) => {
    try {
      setLoading(true)
      setError(null)
      const response = await axios.post(
        `/api/posts/${id}/comments/${commentId}`,
        { content },
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        }
      )
      setComments((prev) =>
        prev.map((comment) =>
          comment._id === commentId
            ? { ...comment, replies: [...comment.replies, response.data] }
            : comment
        )
      )
    } catch (error) {
      setError('Failed to add reply. Please try again.')
      console.error('Error adding reply:', error)
    } finally {
      setLoading(false)
    }
  }

  const likeComment = async (commentId) => {
    try {
      setError(null)
      const response = await axios.post(
        `/api/posts/${id}/comments/${commentId}/likes`,
        {},
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        }
      )
      setComments((prev) =>
        prev.map((comment) =>
          comment._id === commentId
            ? { ...comment, likes: response.data.likes }
            : comment
        )
      )
    } catch (error) {
      setError('Failed to like comment.')
      console.error('Error liking comment:', error)
    }
  }

  const likeReply = async (commentId, replyId) => {
    try {
      setError(null)
      const response = await axios.post(
        `/api/posts/${id}/comments/${commentId}/replies/${replyId}/likes`,
        {},
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        }
      )
      setComments((prev) =>
        prev.map((comment) =>
          comment._id === commentId
            ? {
                ...comment,
                replies: comment.replies.map((reply) =>
                  reply._id === replyId ? { ...reply, likes: response.data.likes } : reply
                ),
              }
            : comment
        )
      )
    } catch (error) {
      setError('Failed to like reply.')
      console.error('Error liking reply:', error)
    }
  }

  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 py-6">
      {/* Add Comment Section */}
      {user && (
        <div className="mb-6">
          <AddComment addComment={addComment} disabled={loading} />
        </div>
      )}

      {/* Error Display */}
      {error && (
        <div className="mb-6 bg-red-50 text-red-700 p-4 rounded-lg flex items-center gap-3">
          <AlertCircle className="w-5 h-5" />
          <span>{error}</span>
        </div>
      )}

      {/* Comments List */}
      <div className="space-y-6">
        {comments.length > 0 ? (
          comments.map((comment) => (
            <Comment
              key={comment._id}
              comment={comment}
              addReply={addReply}
              likeComment={likeComment}
              likeReply={likeReply}
            />
          ))
        ) : (
          <div className="text-center text-gray-500 py-8">
            <p className="text-lg">No comments yet</p>
            <p className="text-sm mt-1">
              {user ? 'Be the first to comment!' : 'Log in to add a comment.'}
            </p>
          </div>
        )}
      </div>

      {/* Loading Overlay */}
      {loading && (
        <div className="fixed inset-0 bg-gray-200 bg-opacity-50 flex items-center justify-center">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-gray-900"></div>
        </div>
      )}
    </div>
  )
}

export default Comments