import React, { useState, useContext } from 'react'
import { format } from 'date-fns'
import { UserContext } from '../../../context/UserContext'
import { Heart } from 'lucide-react'

const Reply = ({ reply, likeReply, commentId }) => {
  const { user } = useContext(UserContext)
  const [isLiked, setIsLiked] = useState(reply.likes.includes(user?._id))

  const handleLikeReply = () => {
    likeReply(commentId, reply._id)
    setIsLiked(!isLiked)
  }

  const formattedDate = format(new Date(reply.createdAt), 'MMM d, yyyy HH:mm')

  return (
    <div className="bg-gray-50 border border-gray-200 rounded-lg p-4 shadow-sm hover:shadow-md transition-shadow">
      <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-3">
        <div className="flex items-center gap-3">
          <img
            src={reply.user.avatar || '/default-avatar.png'}
            alt={`${reply.user.firstName}'s avatar`}
            className="w-8 h-8 rounded-full object-cover border border-gray-200"
            onError={(e) => (e.target.src = '/default-avatar.png')}
          />
          <div>
            <span className="font-medium text-gray-900">{reply.user.firstName}</span>
            <time className="block text-sm text-gray-500" dateTime={reply.createdAt}>
              {formattedDate}
            </time>
          </div>
        </div>

        <div className="flex items-center gap-2 text-gray-600">
          <button
            onClick={handleLikeReply}
            className="flex items-center gap-1 hover:text-red-500 transition-colors group"
          >
            <Heart
              className={`w-4 h-4 ${isLiked ? 'fill-red-500 text-red-500' : 'group-hover:fill-red-500 group-hover:text-red-500'}`}
            />
            <span>{reply.likes.length > 0 ? reply.likes.length : 0}</span>
          </button>
        </div>
      </div>

      <p className="mt-2 text-gray-700 leading-relaxed">{reply.content}</p>
    </div>
  )
}

export default Reply