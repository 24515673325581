import React, { useContext, useState } from 'react';
import { Navigate, Link } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UserContext } from '../../context/UserContext';
import { Mail, Lock } from 'lucide-react';
import Loading from '../../components/shared/Loading';

const LoginPage = () => {
  const initialState = {
    email: '',
    password: '',
  };

  const [formData, setFormData] = useState(initialState);
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const { setUser, setToken, setIsAuthenticated } = useContext(UserContext);

  const onChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const url = '/api/users/login';
      const { data } = await axios.post(url, formData, { withCredentials: true });
      const token = data.token;
      localStorage.setItem('token', token);
      setToken(token);
      setIsAuthenticated(true);
      setUser(data);
      setLoggedIn(true);
      toast.success('Login successful! Redirecting...');
    } catch (error) {
      setLoading(false);
      setErrors(error.response.data);
      console.log('Login error', error);
      toast.error('Login failed. Please check your credentials.');
    }
  };

  if (loggedIn) {
    return <Navigate to={'/'} />;
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-indigo-600 via-purple-500 to-pink-500 p-4">
      <div className="bg-white rounded-xl shadow-2xl p-8 w-full max-w-lg transform transition-all hover:scale-105">
        <form onSubmit={onSubmit} className="space-y-6">
          {/* Header */}
          <div className="text-center">
            <h1 className="text-3xl font-bold text-gray-900 mb-2">Welcome Back</h1>
            <p className="text-sm text-gray-500">Log in to continue your coding journey!</p>
          </div>

          {/* Email Input */}
          <div className="relative">
            <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
              Email Address
            </label>
            <div className="flex items-center border rounded-lg overflow-hidden focus-within:ring-2 focus-within:ring-indigo-400 transition-all">
              <span className="pl-3 text-gray-500">
                <Mail size={20} />
              </span>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={onChange}
                placeholder="Your email"
                className={`w-full py-3 px-3 border-0 focus:outline-none ${errors.email ? 'text-red-600' : 'text-gray-900'}`}
                aria-label="Email Address"
              />
            </div>
            {errors.email && <p className="text-red-500 text-xs mt-1">{errors.email}</p>}
          </div>

          {/* Password Input */}
          <div className="relative">
            <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-1">
              Password
            </label>
            <div className="flex items-center border rounded-lg overflow-hidden focus-within:ring-2 focus-within:ring-indigo-400 transition-all">
              <span className="pl-3 text-gray-500">
                <Lock size={20} />
              </span>
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={onChange}
                placeholder="Your password"
                className={`w-full py-3 px-3 border-0 focus:outline-none ${errors.password ? 'text-red-600' : 'text-gray-900'}`}
                aria-label="Password"
              />
            </div>
            {errors.password && <p className="text-red-500 text-xs mt-1">{errors.password}</p>}
          </div>

          {/* Forgot Password Link */}
          <div className="text-right">
            <Link
              to="/forgot-password"
              className="text-sm text-indigo-600 hover:underline hover:text-indigo-800 transition-colors"
            >
              Forgot Password?
            </Link>
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            disabled={loading}
            className={`w-full py-3 bg-indigo-600 text-white rounded-lg font-semibold hover:bg-indigo-700 focus:ring-4 focus:ring-indigo-300 transition-all duration-300 ease-in-out ${loading ? 'opacity-60 cursor-not-allowed' : ''}`}
          >
            {loading ? (
              <span className="flex items-center justify-center">
                <svg className="animate-spin h-5 w-5 mr-2 text-white" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none" />
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8h8a8 8 0 01-16 0z" />
                </svg>
                Logging In...
              </span>
            ) : (
              'Log In'
            )}
          </button>

          {/* Sign Up Link */}
          <div className="text-center text-sm text-gray-600">
            Don’t have an account?{' '}
            <Link to="/register" className="text-indigo-600 font-medium hover:underline hover:text-indigo-800 transition-colors">
              Sign Up
            </Link>
          </div>
        </form>

        {/* Toast Notifications */}
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </div>
  );
};

export default LoginPage;