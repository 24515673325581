import React from 'react';
import { Link } from 'react-router-dom';
import { 
  Code, 
  Folder, 
  Zap, 
  ChevronRight, 
  BookOpen, 
  Award, 
  Calendar, 
  Star,
  CheckCircle, 
  Users, 
  ArrowRight,
  Quote
} from 'lucide-react';

const HomePage = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100">
      {/* Hero Section with animated elements */}
      <header className="bg-gradient-to-r from-indigo-800 via-indigo-700 to-indigo-600 text-white overflow-hidden relative pt-16 pb-24">
        {/* Background patterns */}
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute -right-10 -top-10 w-64 h-64 bg-yellow-400 opacity-10 rounded-full"></div>
          <div className="absolute left-10 bottom-10 w-40 h-40 bg-blue-400 opacity-10 rounded-full"></div>
          <div className="absolute right-1/3 bottom-1/4 w-32 h-32 bg-purple-400 opacity-10 rounded-full"></div>
        </div>
        
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-10 md:py-20 relative z-10">
          <div className="text-center md:text-left md:flex md:items-center md:justify-between">
            <div className="md:w-3/5">
              <span className="inline-block px-4 py-1 rounded-full bg-indigo-900/50 text-cyan-300 text-sm font-medium mb-5">
                The Ultimate React Learning Path
              </span>
              <h1 className="text-4xl font-extrabold sm:text-5xl md:text-6xl tracking-tight leading-tight">
                Master React in <span className="text-cyan-300 font-bold relative">
                  30 Days
                  <svg className="absolute -bottom-2 left-0 w-full" viewBox="0 0 100 15" preserveAspectRatio="none">
                    <path d="M0,5 C30,15 70,15 100,5 L100,15 L0,15 Z" fill="#fcd34d" fillOpacity="0.3" />
                  </svg>
                </span>
              </h1>

              <p className="mt-6 text-lg sm:text-xl max-w-2xl mx-auto md:mx-0 text-indigo-100">
                Structured lessons, real-world projects, and hands-on challenges—designed by industry experts to take you from beginner to professional developer.
              </p>
              
              <div className="mt-8 flex flex-col sm:flex-row justify-center md:justify-start space-y-4 sm:space-y-0 sm:space-x-4">
                <Link
                  to="/30-days-of-react"
                  className="inline-flex items-center justify-center bg-yellow-400 text-indigo-900 px-8 py-4 rounded-lg font-semibold shadow-lg hover:bg-yellow-300 transition-all duration-300 transform hover:scale-105 focus:ring-2 focus:ring-yellow-500 focus:outline-none"
                >
                  Start Learning <ChevronRight className="ml-2 h-5 w-5" />
                </Link>
                <Link
                  to="/react-cheatsheet"
                  className="inline-flex items-center justify-center bg-indigo-900/50 backdrop-blur-sm text-white border border-indigo-500 px-8 py-4 rounded-lg font-semibold hover:bg-indigo-900/70 transition-all duration-300 focus:ring-2 focus:ring-indigo-400 focus:outline-none"
                >
                  View Cheatsheet <BookOpen className="ml-2 h-5 w-5" />
                </Link>
              </div>
              
              <div className="mt-8 flex items-center justify-center md:justify-start space-x-1 text-sm text-indigo-200">
                <Users className="h-4 w-4" />
                <span>Join <strong>500, 000+</strong> developers who have completed the course</span>
              </div>
            </div>
            
            <div className="hidden md:block md:w-2/5">
              <div className="relative">
                <div className="absolute inset-0 bg-gradient-to-br from-yellow-400 to-cyan-400 rounded-lg transform rotate-3 opacity-20"></div>
                <div className="relative bg-indigo-800/70 backdrop-blur-sm p-6 rounded-lg border border-indigo-500 shadow-2xl">
                  <div className="text-sm text-cyan-300 mb-2 flex items-center">
                    <Star className="h-4 w-4 mr-1" /> FEATURED LESSON
                  </div>
                  <h3 className="text-xl font-bold mb-2">React Hooks Mastery</h3>
                  <p className="text-indigo-100 text-sm mb-4">Learn how useEffect, useState, useContext and custom hooks can transform your components.</p>
                  <div className="flex justify-between items-center">
                    <span className="text-xs text-indigo-300">Day 21 of 30</span>
                    <Link 
                      to="/30-days-of-react/day/15" 
                      className="text-cyan-300 hover:text-cyan-200 text-sm flex items-center"
                    >
                      View Lesson <ArrowRight className="ml-1 h-3 w-3" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* Stats band */}
      <section className="py-8 bg-white border-b border-gray-200">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8 text-center">
            <div>
              <p className="text-3xl font-bold text-indigo-600">30+</p>
              <p className="text-gray-600 text-sm">Lessons</p>
            </div>
            <div>
              <p className="text-3xl font-bold text-indigo-600">15+</p>
              <p className="text-gray-600 text-sm">Projects</p>
            </div>
            <div>
              <p className="text-3xl font-bold text-indigo-600">80+</p>
              <p className="text-gray-600 text-sm">Code Exercises</p>
            </div>
            <div>
              <p className="text-3xl font-bold text-indigo-600">24/7</p>
              <p className="text-gray-600 text-sm">Resource Access</p>
            </div>
          </div>
        </div>
      </section>

      {/* Highlights Section with improved cards */}
      <section className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <span className="bg-indigo-100 text-indigo-800 text-sm font-medium px-3 py-1 rounded-full">CURRICULUM</span>
            <h2 className="mt-4 text-3xl font-extrabold text-gray-900">
              What You'll Learn
            </h2>
            <p className="mt-3 max-w-2xl mx-auto text-gray-500 text-lg">
              A comprehensive curriculum that takes you from beginner concepts to advanced React techniques
            </p>
          </div>
          
          <div className="mt-12 grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="group bg-white p-8 rounded-xl shadow-md hover:shadow-xl transition-all duration-300 border border-gray-100 relative overflow-hidden">
              <div className="absolute right-0 top-0 h-24 w-24 bg-blue-100 rounded-bl-full opacity-50 group-hover:opacity-100 transition-opacity duration-300"></div>
              <div className="relative">
                <div className="w-14 h-14 bg-blue-100 text-blue-600 rounded-xl flex items-center justify-center mb-6 group-hover:bg-blue-600 group-hover:text-white transition-colors duration-300">
                  <Code className="h-6 w-6" />
                </div>
                <h3 className="text-xl font-bold text-gray-900 mb-3">React Fundamentals</h3>
                <p className="text-gray-600 mb-6">
                  Build a solid foundation with JSX, components, props, state management, and event handling.
                </p>
                <ul className="space-y-2">
                  {['JSX Elements', 'Components', 'Props & State','list and map', 'Conditional Rendering', 'Event Handling', 'Forms'].map((item, i) => (
                    <li key={i} className="flex items-center text-sm text-gray-500">
                      <CheckCircle className="h-4 w-4 text-green-500 mr-2" />
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            
            <div className="group bg-white p-8 rounded-xl shadow-md hover:shadow-xl transition-all duration-300 border border-gray-100 relative overflow-hidden">
              <div className="absolute right-0 top-0 h-24 w-24 bg-indigo-100 rounded-bl-full opacity-50 group-hover:opacity-100 transition-opacity duration-300"></div>
              <div className="relative">
                <div className="w-14 h-14 bg-indigo-100 text-indigo-600 rounded-xl flex items-center justify-center mb-6 group-hover:bg-indigo-600 group-hover:text-white transition-colors duration-300">
                  <Folder className="h-6 w-6" />
                </div>
                <h3 className="text-xl font-bold text-gray-900 mb-3">Real-World Projects</h3>
                <p className="text-gray-600 mb-6">
                  Apply your knowledge by building practical applications from scratch with step-by-step guidance.
                </p>
                <ul className="space-y-2">
                  {['User Dashboard', 'Data Visualization', 'API Integration', 'State Management'].map((item, i) => (
                    <li key={i} className="flex items-center text-sm text-gray-500">
                      <CheckCircle className="h-4 w-4 text-green-500 mr-2" />
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            
            <div className="group bg-white p-8 rounded-xl shadow-md hover:shadow-xl transition-all duration-300 border border-gray-100 relative overflow-hidden">
              <div className="absolute right-0 top-0 h-24 w-24 bg-purple-100 rounded-bl-full opacity-50 group-hover:opacity-100 transition-opacity duration-300"></div>
              <div className="relative">
                <div className="w-14 h-14 bg-purple-100 text-purple-600 rounded-xl flex items-center justify-center mb-6 group-hover:bg-purple-600 group-hover:text-white transition-colors duration-300">
                  <Zap className="h-6 w-6" />
                </div>
                <h3 className="text-xl font-bold text-gray-900 mb-3">Advanced Techniques</h3>
                <p className="text-gray-600 mb-6">
                  Take your skills to the professional level with advanced patterns and optimization strategies.
                </p>
                <ul className="space-y-2">
                  {['React Hooks', 'Context API','Custom Hooks',  'Error Handling', 'Routing', 'API Integration', 'Performance Optimization', 'Testing', 'Deployment'].map((item, i) => (
                    <li key={i} className="flex items-center text-sm text-gray-500">
                      <CheckCircle className="h-4 w-4 text-green-500 mr-2" />
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Features Section with improved layout */}
      <section className="py-20 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <span className="bg-indigo-100 text-indigo-800 text-sm font-medium px-3 py-1 rounded-full">BENEFITS</span>
            <h2 className="mt-4 text-3xl font-extrabold text-gray-900">
              Why Choose Our Course
            </h2>
            <p className="mt-3 max-w-2xl mx-auto text-gray-500 text-lg">
              Designed to provide the most effective learning experience for developers at all levels
            </p>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-12">
            <div className="bg-white p-6 rounded-xl shadow-sm hover:shadow-md transition-shadow duration-300 border border-gray-100">
              <div className="w-12 h-12 bg-indigo-100 text-indigo-600 rounded-lg flex items-center justify-center mb-4">
                <Calendar className="h-5 w-5" />
              </div>
              <h3 className="text-lg font-bold text-gray-900">Structured Learning Path</h3>
              <p className="mt-2 text-gray-600">Day-by-day curriculum designed to build your skills progressively without overwhelming you.</p>
            </div>
            
            <div className="bg-white p-6 rounded-xl shadow-sm hover:shadow-md transition-shadow duration-300 border border-gray-100">
              <div className="w-12 h-12 bg-indigo-100 text-indigo-600 rounded-lg flex items-center justify-center mb-4">
                <BookOpen className="h-5 w-5" />
              </div>
              <h3 className="text-lg font-bold text-gray-900">Comprehensive Resources</h3>
              <p className="mt-2 text-gray-600">Access to code examples, tutorials, and additional materials to supplement your learning.</p>
            </div>
            
            <div className="bg-white p-6 rounded-xl shadow-sm hover:shadow-md transition-shadow duration-300 border border-gray-100">
              <div className="w-12 h-12 bg-indigo-100 text-indigo-600 rounded-lg flex items-center justify-center mb-4">
                <Award className="h-5 w-5" />
              </div>
              <h3 className="text-lg font-bold text-gray-900">Project-Based Learning</h3>
              <p className="mt-2 text-gray-600">Learn by doing with projects that simulate real-world applications and reinforce concepts.</p>
            </div>

            <div className="bg-white p-6 rounded-xl shadow-sm hover:shadow-md transition-shadow duration-300 border border-gray-100">
              <div className="w-12 h-12 bg-indigo-100 text-indigo-600 rounded-lg flex items-center justify-center mb-4">
                <Users className="h-5 w-5" />
              </div>
              <h3 className="text-lg font-bold text-gray-900">Community Support</h3>
              <p className="mt-2 text-gray-600">Connect with fellow learners, share your progress, and get help when you need it.</p>
            </div>

            <div className="bg-white p-6 rounded-xl shadow-sm hover:shadow-md transition-shadow duration-300 border border-gray-100">
              <div className="w-12 h-12 bg-indigo-100 text-indigo-600 rounded-lg flex items-center justify-center mb-4">
                <Star className="h-5 w-5" />
              </div>
              <h3 className="text-lg font-bold text-gray-900">Industry-Relevant Skills</h3>
              <p className="mt-2 text-gray-600">Focus on the techniques and patterns used in professional React development environments.</p>
            </div>

            <div className="bg-white p-6 rounded-xl shadow-sm hover:shadow-md transition-shadow duration-300 border border-gray-100">
              <div className="w-12 h-12 bg-indigo-100 text-indigo-600 rounded-lg flex items-center justify-center mb-4">
                <Zap className="h-5 w-5" />
              </div>
              <h3 className="text-lg font-bold text-gray-900">Flexible Pacing</h3>
              <p className="mt-2 text-gray-600">Learn at your own speed with content that's accessible 24/7 and designed for self-paced learning.</p>
            </div>
          </div>
        </div>
      </section>

      {/* Testimonials Section (New) */}
      <section className="py-16 bg-white border-t border-gray-100">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-12">
            <span className="bg-indigo-100 text-indigo-800 text-sm font-medium px-3 py-1 rounded-full">TESTIMONIALS</span>
            <h2 className="mt-4 text-3xl font-extrabold text-gray-900">
              What Our Students Say
            </h2>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {[
              {
                name: "Nebeolisa Chiemezie Samson",
                role: "Student",
                quote: "Actually i believe Asabeneh chanllenges teach more than many videos and many docmentation i have seen, i start programming as a complete novice, i knew nothing about programming or computer itself, when i start i watched youtubes , many videos many write up , but not untill i came across Asabeneh challanges , and with my experinces now in programming i can tell you , it takes great energy and courage to cool down and put up a detialed document like Asabeneh's. Not only did he make sure that the information was passed to a beginner , he added a lot of examples and excercises to make sure that the informations was also retend. He is a good example of what a teacher should be. i really appreciate Asabeneh for all the Knowledge i have gotten from his site, and with his help i was able to acomplish might things. thanks and keep it up."
              },
              {
                name: "Kien To",
                role: "Student",
                quote: "I expect to learn React from absolute zero and this course brings me to absolute hero"
              },
              {
                name: "Abiodun Alao",
                role: "Backend Developer",
                quote: "The material is really awesome and great and I will say it's a masterpiece because it makes your learning more robust and it fast tracks your learning because the way the resources are being structured is top-notch. I will definitely recommend it to anybody who want to start a career in tech."
              }
            ].map((testimonial, i) => (
              <div key={i} className="bg-gray-50 p-6 rounded-xl border border-gray-100">
                <div className="flex items-center mb-4">
                  <div className="bg-indigo-600 text-white w-10 h-10 rounded-full flex items-center justify-center font-bold">
                    {testimonial.name.charAt(0)}
                  </div>
                  <div className="ml-3">
                    <h4 className="font-semibold text-gray-900">{testimonial.name}</h4>
                    <p className="text-sm text-gray-500">{testimonial.role}</p>
                  </div>
                </div>
              
                <Quote className='inline-block w-6 h-6 text-gray-400' />
          <p className="text-gray-600 italic">"{testimonial.quote}"</p>
          <Quote className='inline-block w-6 h-6 text-gray-400 ml-2' />

                <div className="mt-3 flex text-yellow-400">
                  {[...Array(5)].map((_, i) => (
                    <Star key={i} className="h-4 w-4 fill-current" />
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action with enhanced design */}
      <section id="get-started" className="py-16 bg-gradient-to-r from-indigo-800 via-indigo-700 to-indigo-600 text-white relative overflow-hidden">
        {/* Background elements */}
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute left-1/4 -bottom-20 w-64 h-64 bg-indigo-500 opacity-20 rounded-full"></div>
          <div className="absolute right-10 top-10 w-40 h-40 bg-yellow-400 opacity-10 rounded-full"></div>
        </div>
        
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
          <div className="text-center md:text-left md:flex md:items-center md:justify-between">
            <div className="md:w-2/3">
              <span className="inline-block px-3 py-1 rounded-full bg-indigo-900/50 text-cyan-300 text-sm font-medium mb-4">
                LIMITED TIME OFFER
              </span>
              <h2 className="text-3xl font-extrabold leading-tight">
                Ready to Transform Your React Skills?
              </h2>
              <p className="mt-4 text-lg text-indigo-100 max-w-2xl">
                Join thousands of developers who have accelerated their careers with our structured approach to mastering React.
              </p>
            </div>
            
            <div className="mt-8 md:mt-0">
              <Link
                to="/register"
                className="inline-flex items-center justify-center bg-yellow-400 text-indigo-900 px-8 py-4 rounded-lg font-semibold shadow-lg hover:bg-yellow-300 transition-all duration-300 transform hover:scale-105 focus:ring-2 focus:ring-yellow-500 focus:outline-none"
              >
                Join Now <ChevronRight className="ml-2 h-5 w-5" />
              </Link>
              <p className="mt-3 text-sm text-indigo-200">No credit card required</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default HomePage;