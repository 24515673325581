import React from 'react'
import { Link } from 'react-router-dom'
import { Heart, MessageCircle, Eye, Calendar } from 'lucide-react'
import Spinner from '../../shared/Spinner'

const Post = (props) => {
  if (!props) {
    return (
      <div className="flex justify-center items-center py-8">
        <Spinner />
      </div>
    )
  }

  const {
    title,
    summary,
    content,
    comments,
    likes,
    views,
    createdAt,
    cover,
    user,
    id,
    status,
  } = props

  const postStatusColor = status?.completed 
    ? 'bg-green-50 border-green-200' 
    : 'bg-white border-gray-200'

  // Format date if createdAt exists
  const formattedDate = createdAt 
    ? new Date(createdAt).toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric'
      })
    : null

  return (
    <article 
      className={`mt-6 mb-8 p-6 rounded-xl shadow-md border ${postStatusColor} 
        hover:shadow-lg hover:-translate-y-1 transition-all duration-300 
        max-w-full mx-auto`}
    >
      <div className="flex flex-col sm:flex-row gap-6">
        {/* Image Section */}
        <div className="sm:w-1/3 flex-shrink-0">
          <Link to={`/30-days-of-react/${id}`} className="block">
            <div className="relative overflow-hidden rounded-lg aspect-video">
              <img
                className="w-full h-full object-cover transition-transform duration-300 hover:scale-105"
                src={cover.url}
                alt={title}
                loading="lazy"
              />
            </div>
          </Link>
        </div>

        {/* Content Section */}
        <div className="sm:w-2/3 flex flex-col justify-between">
          <div>
            <Link to={`/30-days-of-react/${id}`}>
              <h2 className="text-2xl font-bold text-gray-900 hover:text-blue-600 
                transition-colors duration-200 line-clamp-2 mb-2">
                {title}
              </h2>
            </Link>
            
            {summary && (
              <p className="text-gray-600 text-sm line-clamp-3 mb-4">
                {summary}
              </p>
            )}
          </div>

          {/* Stats and Meta */}
          <div className="flex flex-col gap-3">
            {formattedDate && (
              <div className="flex items-center text-gray-500 text-sm">
                <Calendar className="w-4 h-4 mr-2" />
                <span>{formattedDate}</span>
              </div>
            )}

            <div className="flex flex-wrap gap-4 text-sm text-gray-600">
              <span 
                className="flex items-center gap-1.5 hover:text-red-500 
                  transition-colors duration-200 cursor-pointer group"
              >
                <Heart className="w-5 h-5 group-hover:fill-red-500 
                  group-hover:stroke-red-500 transition-colors duration-200" />
                <span>{likes.length > 0 ? likes.length : 0}</span>
              </span>

              <span 
                className="flex items-center gap-1.5 hover:text-blue-500 
                  transition-colors duration-200 cursor-pointer group"
              >
                <MessageCircle className="w-5 h-5 group-hover:stroke-blue-500 
                  transition-colors duration-200" />
                <span>{comments.length > 0 ? comments.length : 0}</span>
              </span>

              {views && (
                <span 
                  className="flex items-center gap-1.5 hover:text-gray-500 
                    transition-colors duration-200 cursor-pointer group"
                >
                  <Eye className="w-5 h-5 group-hover:stroke-gray-500 
                    transition-colors duration-200" />
                  <span>{views}</span>
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </article>
  )
}

export default Post