import React, { useState, useEffect } from "react";
import Post from "./Post";
import { AlertCircle } from "lucide-react";

const Posts = ({ posts }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Simulate loading state
  useEffect(() => {
    if (posts) {
      const timer = setTimeout(() => setLoading(false), 300);
      return () => clearTimeout(timer);
    }
  }, [posts]);

  // Error handling for invalid posts prop
  if (!posts || !Array.isArray(posts)) {
    return (
      <div className="mx-auto px-4 sm:px-6 lg:px-8 py-8 max-w-7xl">
        <div className="flex items-center justify-center min-h-[50vh]">
          <div className="bg-red-50 text-red-700 p-4 rounded-lg flex items-center gap-3">
            <AlertCircle className="w-6 h-6" />
            <span>Error: No valid posts data available</span>
          </div>
        </div>
      </div>
    );
  }

  const postList = posts.map((post) => (
    <div key={post.id} className="w-full">
      <Post {...post} />
    </div>
  ));

  return (
    <div className="mx-auto px-4 sm:px-6 lg:px-8 py-12 max-w-7xl">
      {/* Header Section */}
      <div className="mb-8">
        <h1 className="text-3xl font-bold text-gray-900 tracking-tight">
          Latest Posts
        </h1>
        <p className="mt-2 text-gray-600 text-sm">
          Showing {posts.length} post{posts.length !== 1 ? "s" : ""}
        </p>
      </div>

      {/* Posts Grid */}
      <div className="space-y-6">
        {loading ? (
          // Loading State
          <div className="space-y-6">
            {[...Array(3)].map((_, index) => (
              <div
                key={index}
                className="bg-white rounded-xl shadow-md p-6 animate-pulse"
              >
                <div className="flex flex-col sm:flex-row gap-6">
                  <div className="sm:w-1/3">
                    <div className="bg-gray-200 h-48 w-full rounded-lg" />
                  </div>
                  <div className="sm:w-2/3 space-y-4">
                    <div className="h-6 bg-gray-200 rounded w-3/4" />
                    <div className="space-y-2">
                      <div className="h-4 bg-gray-200 rounded w-full" />
                      <div className="h-4 bg-gray-200 rounded w-5/6" />
                    </div>
                    <div className="flex gap-4">
                      <div className="h-4 bg-gray-200 rounded w-12" />
                      <div className="h-4 bg-gray-200 rounded w-12" />
                      <div className="h-4 bg-gray-200 rounded w-12" />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : postList.length > 0 ? (
          postList
        ) : (
          // Empty State
          <div className="flex items-center justify-center min-h-[50vh]">
            <div className="text-center text-gray-500">
              <AlertCircle className="w-12 h-12 mx-auto mb-4" />
              <p className="text-lg">No posts available yet</p>
              <p className="text-sm mt-2">Check back later for new content!</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Posts;


