import React, { useState, useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { UserContext } from '../../../context/UserContext';
import { NavLink as Link, useNavigate, useLocation } from 'react-router-dom';
import { 
  Menu, 
  Info, 
  Database, 
  User, 
  LogOut, 
  PlusCircle, 
  X, 
  LogIn, 
  UserPlus,
  BookOpen,
  Home,
  Braces
} from 'lucide-react';


const Header = ({ title }) => {
  const { user, setUser } = useContext(UserContext);
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const isLoggedIn = !!user;
  
  // Close mobile menu when path changes or on outside click
  useEffect(() => {
    setIsOpen(false);
    
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [location.pathname]);

  // Handle logout
  const logout = async () => {
    try {
      await fetch('/api/users/logout', { method: 'POST', credentials: 'include' });
      setUser(null);
      navigate('/login');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  // Common link styling with refined states
  const linkClass = ({ isActive }) =>
    `flex items-center space-x-2 px-4 py-2.5 rounded-md transition-all duration-200 ${
      isActive
        ? 'text-white bg-indigo-700 font-medium shadow-sm'
        : 'text-indigo-100 hover:text-white hover:bg-indigo-800/40'
    }`;

  return (
    <header className="bg-gradient-to-r from-indigo-900 to-indigo-800 text-white shadow-md sticky top-0 z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Navbar Container */}
        <div className="flex justify-between items-center h-16">
          {/* Logo/Title with subtle animation */}
          <Link
            to="/"
            className="text-2xl font-bold tracking-tight text-white hover:text-yellow-300 transition-all duration-300 transform hover:scale-105 flex items-center"
            aria-label="Homepage"
          >
            <Home className="w-6 h-6 mr-2" /> 
            
            
            <span>{title || '30 Days Of React'}</span>
          </Link>

          {/* Desktop Navigation */}
          <nav className="hidden md:flex items-center space-x-2">
            <Link to="/30-days-of-react" className={linkClass}>
              <Menu className="w-5 h-5" />
              <span>Course</span>
            </Link>
            <Link to="/about" className={linkClass}>
              <Info className="w-5 h-5" />
              <span>About</span>
            </Link>
            <Link to="/terminologies" className={linkClass}>
              <BookOpen className="w-5 h-5" />
              <span>Terminologies</span>
            </Link>
            <Link to="/react-cheatsheet" className={linkClass}>
              <BookOpen className="w-5 h-5" />
              <span>Cheatsheet</span>
            </Link>

            {/* Conditional Links for Logged-In Users */}
            {isLoggedIn && (
              <Link to={`/profile/${user?._id}`} className={linkClass}>
                <User className="w-5 h-5" />
                <span>Profile</span>
              </Link>
            )}
            
            {user?.role?.[0] === 'Admin' && (
              <>
                <Link to="/dashboard" className={linkClass}>
                  <Database className="w-5 h-5" />
                  <span>Dashboard</span>
                </Link>
                <Link to="/30-days-of-react/create" className={linkClass}>
                  <PlusCircle className="w-5 h-5" />
                  <span>Add</span>
                </Link>
              </>
            )}

            {/* Auth Section */}
            <div className="ml-2 pl-2 border-l border-indigo-700">
              {!isLoggedIn ? (
                <Link to="/register" className="flex items-center space-x-2 px-4 py-2 bg-yellow-500 text-indigo-900 font-medium rounded-md hover:bg-yellow-400 transition-colors duration-200 shadow-sm">
                  <UserPlus className="w-5 h-5" /> 
                  <span>Sign up</span>
                </Link>
              ) : (
                <button
                  onClick={logout}
                  className="flex items-center space-x-2 px-4 py-2 rounded-md text-indigo-100 hover:text-white hover:bg-indigo-800/40 transition-colors duration-200"
                  aria-label="Logout"
                >
                  <LogOut className="w-5 h-5" />
                  <span>Logout</span>
                </button>
              )}
            </div>
          </nav>

          {/* Mobile Menu Button with animation */}
          <button
            className="md:hidden p-2 rounded-md text-white hover:bg-indigo-800/40 transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-yellow-400"
            onClick={() => setIsOpen(!isOpen)}
            aria-expanded={isOpen}
            aria-controls="mobile-menu"
            aria-label="Toggle menu"
          >
            {isOpen ? (
              <X className="w-6 h-6 transform rotate-0 animate-in" />
            ) : (
              <Menu className="w-6 h-6 transform rotate-0 animate-in" />
            )}
          </button>
        </div>

        {/* Mobile Navigation with improved animation */}
        <div
          ref={menuRef}
          id="mobile-menu"
          className={`md:hidden transition-all duration-300 ease-in-out overflow-hidden ${
            isOpen 
              ? 'max-h-screen opacity-100 pb-4' 
              : 'max-h-0 opacity-0'
          }`}
        >
          <nav className="bg-indigo-800/95 backdrop-blur-sm rounded-lg shadow-lg mt-2">
            <ul className="flex flex-col p-4 space-y-1">
              <li>
                <Link to="/30-days-of-react" className={linkClass}>
                  <Menu className="w-5 h-5" />
                  <span>Course</span>
                </Link>
              </li>
              <li>
                <Link to="/about" className={linkClass}>
                  <Info className="w-5 h-5" />
                  <span>About</span>
                </Link>
              </li>
              <li>
                <Link to="/terminologies" className={linkClass}>
                  <BookOpen className="w-5 h-5" />
                  <span>Terminologies</span>
                </Link>
              </li>
              <li>
                <Link to="/react-cheatsheet" className={linkClass}>
                  <BookOpen className="w-5 h-5" />
                  <span>Cheatsheet</span>
                </Link>
              </li>
              
              {isLoggedIn && (
                <li>
                  <Link to={`/profile/${user?._id}`} className={linkClass}>
                    <User className="w-5 h-5" />
                    <span>Profile</span>
                  </Link>
                </li>
              )}
              
              {user?.role?.[0] === 'Admin' && (
                <>
                  <li>
                    <Link to="/dashboard" className={linkClass}>
                      <Database className="w-5 h-5" />
                      <span>Dashboard</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/30-days-of-react/create" className={linkClass}>
                      <PlusCircle className="w-5 h-5" />
                      <span>Add</span>
                    </Link>
                  </li>
                </>
              )}
              
              <li className="pt-2 mt-2 border-t border-indigo-700">
                {!isLoggedIn ? (
                  <Link to="/register" className="flex items-center space-x-2 w-full px-4 py-2.5 bg-yellow-500 text-indigo-900 font-medium rounded-md hover:bg-yellow-400 transition-colors duration-200 shadow-sm">
                    <UserPlus className="w-5 h-5" /> 
                    <span>Sign up</span>
                  </Link>
                ) : (
                  <button
                    onClick={logout}
                    className="w-full flex items-center justify-center space-x-2 px-4 py-2.5 rounded-md bg-indigo-700/50 text-white hover:bg-indigo-700 transition-colors duration-200"
                  >
                    <LogOut className="w-5 h-5" />
                    <span>Logout</span>
                  </button>
                )}
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  title: PropTypes.string,
};

export default Header;